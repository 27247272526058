// Module 3: Chief engineer, leader, etc use module 3 to evaluate how the program is tracking towards the next milestone
import React from "react";
import "./achieve.css";
import AchieveComeSoon from "../ComeSoon/AchieveComeSoon";
 

const Achieve = () => {
   
  return (
    <div>
      <AchieveComeSoon/>
    </div>
  );
};

export default Achieve;
