// this is the team page to display all the team member information
import React from 'react';
import './TeamPage.css';

const TeamPage = () => {
  const members = [
    { initials: 'JC', name: 'Josh Cottrell', role: "Head Engineer", email: 'josh.cottrell@aescape.com', groupRole: 'Admin' },
    { initials: 'MD', name: 'Max Davis', role: "Senior Mechanical Engineer", email: 'max.davis@aescape.com' },
    { initials: 'LWD', name: 'Li Wei Dong', role: "Software Engineer", email: 'li.weidong@aescape.com' },
    { initials: 'GP', name: 'Gert Petja', role: "Quality Assurance", email: 'gert.petja@aescape.com' },
    { initials: 'AO', name: 'Albert Olszewski', role: "Senior Electrical Engineer", email: 'albert.olszewski@aescape.com' },
    { initials: 'JG', name: 'Jesse Galway', role: "Mechanical Engineer", email: 'jesse.galway@aescape.com' },
    { initials: 'NN', name: 'Nick Nelson', role: "Mechanical Engineer", email: 'nick.nelson@aescape.com' },
  ];

  return (
    <div className="team-table-container">
      <h2>Team members</h2>
      <table className="team-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Email Address</th>
          </tr>
        </thead>

        <tbody>
          {members.map((member, index) => (
            <tr key={index}>
              <td>
                <div className="member-info">
                  <div className="avatar">{member.initials}</div>
                  <div>
                    <span className="member-name">{member.name}</span>
                    {member.groupRole && <span className="groupRole-badge">{member.groupRole}</span>}
                  </div>
                </div>
              </td>
              <td>{member.role}</td>
              <td>{member.email}</td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default TeamPage;
