import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../AppContext";
import { SpecificantionsFormat } from "./SpecificantionsFormat";
import './keySpecificationLayout.css'

const SpecificationArea = ({ specData }) => {
  const { clickedIssue, specificationsMenu, setSpecificationsMenu, isOpenSpecification } =
    useAppContext();
  const [specificationArray, setSpecificationArray] = useState([]);
 // const temporaryMenu = ["Mfg. Compatibility", ""];

  useEffect(() => {
    if (specData) {
      const subsystems = [
        ...new Set(specData.map((spec) => spec._source.subsystem)),
      ];
      setSpecificationArray(subsystems);
      if (subsystems.length > 0) {
        setSpecificationsMenu(subsystems[0]);
      }
    }
  }, [specData, setSpecificationsMenu]);

  const handleSwicthPage = (e) => {
    setSpecificationsMenu(e.target.value);
  };

  return (
    <div
      className="rightSideInforArea"
      style={{
        width: isOpenSpecification ? '30%' : '0%',
        opacity: isOpenSpecification ? 1 : 0,
        transition: 'width 0.5s ease, opacity 0.5s ease',
      }}
    >
      <div className="specificationsArea">
        <label className="keySpecificationTittle">Key Specifications</label>

        {(!clickedIssue || !specData) && (
          <p className="sub-tittle">Search Issue number and keywords to populate this section</p>
        )}

        {clickedIssue && specData && (
          <>
            <div className="menuListWrapper">
              <div className="menuList">
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  {specificationArray.map((subsystem) => (
                    <button
                      key={subsystem}
                      value={subsystem}
                      onClick={handleSwicthPage}
                      className={
                        specificationsMenu === subsystem ? "active" : ""
                      }
                    >
                      {subsystem}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            
            <SpecificantionsFormat
              menuPage={specificationsMenu}
              specData={specData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SpecificationArea;
