// FormHeader.jsx
import React from "react";
import PropTypes from "prop-types";

const FormHeader = ({ companyImage, title, subtitle, className }) => {
  return (
    <div className={className}>
      <img
        src={companyImage}
        alt="Company Logo"
        className="compangLogo"
        draggable="false" // This disables image dragging
      />
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

// Add PropTypes to ensure correct types for title and subtitle
FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string, // the layout of this form header: color, background, border, size
  companyImage: PropTypes.string, // the company icon
};

export default FormHeader;
