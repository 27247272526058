import React from "react";
import PropTypes from "prop-types";

const Setting = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d= "M17.3333 4C16.4074 4 15.6667 4.74474 15.6667 5.65116C15.6667 6.55759 16.4074 7.30233 17.3333 7.30233C18.2593 7.30233 19 6.55759 19 5.65116C19 4.74474 18.2593 4 17.3333 4ZM13.8057 4.65116C14.2429 3.11801 15.6604 2 17.3333 2C19.3529 2 21 3.62919 21 5.65116C21 7.67313 19.3529 9.30233 17.3333 9.30233C15.6604 9.30233 14.2429 8.18432 13.8057 6.65116L4 6.65116C3.44772 6.65116 3 6.20345 3 5.65116C3 5.09888 3.44772 4.65116 4 4.65116L13.8057 4.65116ZM6.66667 10.1511C5.74069 10.1511 5 10.8959 5 11.8023C5 12.7087 5.74069 13.4535 6.66667 13.4535C7.59265 13.4535 8.33333 12.7087 8.33333 11.8023C8.33333 10.8959 7.59265 10.1511 6.66667 10.1511ZM3 11.8023C3 9.78033 4.64713 8.15114 6.66667 8.15114C8.33964 8.15114 9.75705 9.26915 10.1943 10.8023L20 10.8023C20.5523 10.8023 21 11.25 21 11.8023C21 12.3546 20.5523 12.8023 20 12.8023L10.1943 12.8023C9.75705 14.3355 8.33964 15.4535 6.66667 15.4535C4.64713 15.4535 3 13.8243 3 11.8023ZM17.3333 16.3023C16.4074 16.3023 15.6667 17.047 15.6667 17.9535C15.6667 18.8599 16.4074 19.6046 17.3333 19.6046C18.2593 19.6046 19 18.8599 19 17.9535C19 17.047 18.2593 16.3023 17.3333 16.3023ZM13.8057 16.9535C14.2429 15.4203 15.6604 14.3023 17.3333 14.3023C19.3529 14.3023 21 15.9315 21 17.9535C21 19.9754 19.3529 21.6046 17.3333 21.6046C15.6604 21.6046 14.2429 20.4866 13.8057 18.9535H4C3.44772 18.9535 3 18.5057 3 17.9535C3 17.4012 3.44772 16.9535 4 16.9535H13.8057Z"
      clipRule="evenodd"
    />
  </svg>
);
Setting.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default Setting;