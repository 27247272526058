import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  initializeFaro,
  createReactRouterV6Options,
  ReactIntegration,
  getWebInstrumentations,
} from "@grafana/faro-react";
import { QueryClient, QueryClientProvider } from "react-query";

// the reusebale library component:
export { default as ButtonOld } from "./Components/ui/Button/ButtonOld.js"; // button old
export { default as InputFields } from "./Components/ui/InputFields/InputFields.js"; // Input

export { default as Button } from "./Components/ui/Button/Button.js"; // button New
export { default as TestTypography } from "./Components/text/TestTypography.js"; // button New
export { default as CheckBox } from "./Components/ui/Checkbox/Checkbox.jsx"; // CheckBox

// the reusebale library format component:
export { default as TextFeild } from "./Components/forms/Text Field/TextField.js";
export { default as TabNav } from "./Components/forms/TabNav Button/TabNav.js";
export { default as FormHeader } from "./Components/forms/Form Header/FormHeader.js";

initializeFaro({
  url: "https://faro-collector-prod-us-east-0.grafana.net/collect/2ad0eda6538fecac2d4a6432b8a29f11",
  app: {
    name: "Demo",
    version: "0.2.1",
    environment: "demo",
  },

  instrumentations: [
    // Mandatory, omits default instrumentations otherwise.
    ...getWebInstrumentations(),

    // React integration for React applications.
    new ReactIntegration({
      router: createReactRouterV6Options({
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType,
      }),
    }),
  ],
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
