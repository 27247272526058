import React from "react";
import PropTypes from "prop-types";
const SvgDriveModule = ({
  size = 24,
  color = "var(--icon-color)",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19 4.499a2.5 2.5 0 0 1-3.012 2.446l-1.28 2.116a3.99 3.99 0 0 1 1.292 3l1.296.261a2.5 2.5 0 1 1-.279 1.473l-1.319-.265a4 4 0 0 1-1.744 1.962l.495 1.511h.051a2.5 2.5 0 1 1-1.472.479l-.497-1.516a3.995 3.995 0 0 1-3.752-1.592l-1.794.848q.015.138.015.28a2.5 2.5 0 1 1-.623-1.651l1.754-.829a4 4 0 0 1 .784-3.564l-.991-1.129a2.5 2.5 0 1 1 1.164-.947L10.07 8.5A4 4 0 0 1 12 8.005c.505 0 .989.094 1.434.265l1.245-2.06A2.5 2.5 0 1 1 19 4.499m-1.5 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0M7 7.006a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5 7.496a2.5 2.5 0 1 0 .001-4.999A2.5 2.5 0 0 0 12 14.502m-6.5 1a1 1 0 1 0-2 0 1 1 0 0 0 2 0m10 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4-5a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
  </svg>
);
SvgDriveModule.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgDriveModule;
