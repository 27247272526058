/*
    this is the bottom area below the graph including checkbox list and zoom-in zoom-out bar
*/
import React from "react";
import { DefaultButton, IconButton, Checkbox } from "@fluentui/react";
import { ShareMultiple20Regular } from "@fluentui/react-icons";
import { useAppContext } from "../../../AppContext";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

/* used for removed the blue border when selected the checkbox of Highlights drop down list area */
const checkboxStyles = mergeStyleSets({
  root: {
    selectors: {
      "& .ms-Checkbox": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox:hover": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox-checkbox": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox-checkbox:hover": {
        borderColor: "#616161 !important",
      },
    },
  },
});

const GraphBot = () => {
  const {
    is2D,
    displayColor,
    setDisplayColor,
    displayName,
    setDisplayName,
    displayLinkColor,
    setDisplayLinkColor,
    isInteractions,
    setIsInteractions,
    settwoDZoomLevel,
    twoDzoomLevel,
    displayErrorNode,
    setDisplayErrorNode,
  } = useAppContext();

  //the function open window
  const handleFullScreenClick = () => {
    // Implement fullscreen functionality here
  };

  //zoom In function
  const handleZoomIn = (value) => {
    if (is2D === "2D") {
      settwoDZoomLevel((pretwoDzoomLevel) =>
        Math.min(pretwoDzoomLevel + value, 2),
      );
    }
  };

  //zoom out function
  const handleZoomOut = (value) => {
    if (is2D === "2D") {
      settwoDZoomLevel((pretwoDzoomLevel) =>
        Math.max(pretwoDzoomLevel - value, 0),
      );
    }
  };

  // the state to control the thumb
  const handleSliderChange = (e) => {
    if (is2D === "2D") {
      const currZoomLevel = parseFloat(e.target.value);
      settwoDZoomLevel(currZoomLevel);
    }
  };

  // the function make the green color could follow the thumb move and increase
  const getGradientBackground = () => {
    if (is2D === "2D") {
      const percentage = twoDzoomLevel / 2.0; //calculate the percentage of bar
      return `linear-gradient(to right, green 0%, green ${percentage * 100}%, #ccc ${percentage * 100}%, #ccc 100%)`;
    }
  };

  //control the color CheckBox
  const handleColorCheckBox = () => {
    setDisplayColor(!displayColor);
  };

  //control the name CheckBox
  const handleNameCheckBox = () => {
    setDisplayName(!displayName);
  };

  //control the name CheckBox
  const handleLinkCheckBox = () => {
    setDisplayLinkColor(!displayLinkColor);
  };

  //control the componemnt integration
  const handleIntegrated = () => {
    setIsInteractions(!isInteractions);
  };

  //control display the error node or not
  const handleDisplayError = () => {
    setDisplayErrorNode(!displayErrorNode);
  };

  return (
    <div className="graphBot">
      {is2D === "Picture" && (
        <div
          style={{
            display: "flex",
            height: "30px",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <Checkbox
            checked={displayColor}
            onChange={handleColorCheckBox}
            disabled
            styles={{
              root: { cursor: "not-allowed" },
              checkbox: {
                width: "16px",
                height: "16px",
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Components{" "}
          </label>

          <Checkbox
            checked={displayLinkColor}
            onChange={handleLinkCheckBox}
            disabled
            styles={{
              root: { cursor: "not-allowed" },
              checkbox: {
                width: "16px",
                height: "16px",
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Relations{" "}
          </label>

          <Checkbox
            checked={displayErrorNode}
            onChange={handleDisplayError}
            disabled
            styles={{
              root: { cursor: "not-allowed" },
              checkbox: {
                width: "16px",
                height: "16px",
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Overage{" "}
          </label>

          <Checkbox
            checked={isInteractions}
            onChange={handleIntegrated}
            disabled
            styles={{
              root: { cursor: "not-allowed" },
              checkbox: {
                width: "16px",
                height: "16px",
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Interactions
          </label>

          <Checkbox
            checked={displayName}
            onChange={handleNameCheckBox}
            disabled
            styles={{
              root: { cursor: "not-allowed" },
              checkbox: {
                width: "16px",
                height: "16px",
              },
            }}
          />
          <label style={{ paddingLeft: "5px" }}> Name</label>
        </div>
      )}

      {is2D === "2D" && (
        <div
          style={{
            display: "flex",
            height: "30px",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <Checkbox
            checked={displayColor}
            onChange={handleColorCheckBox}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "16px",
                height: "16px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                width: "100%",
                height: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Components{" "}
          </label>

          <Checkbox
            checked={displayLinkColor}
            onChange={handleLinkCheckBox}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "16px",
                height: "16px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                width: "100%",
                height: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Relations{" "}
          </label>

          <Checkbox
            checked={displayErrorNode}
            onChange={handleDisplayError}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "16px",
                height: "16px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                width: "100%",
                height: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            {" "}
            Overage{" "}
          </label>

          <Checkbox
            checked={displayName}
            onChange={handleNameCheckBox}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "16px",
                height: "16px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                width: "100%",
                height: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
              },
            }}
          />
          <label style={{ paddingLeft: "5px", paddingRight: "15px" }}>
            Name
          </label>

          <Checkbox
            checked={isInteractions}
            onChange={handleIntegrated}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "16px",
                height: "16px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                width: "100%",
                height: "100%",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
              },
            }}
          />
          <label style={{ paddingLeft: "5px" }}> Interactions</label>
        </div>
      )}

      <div
        style={{
          marginLeft: "auto",
          marginRight: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <DefaultButton
          style={{
            fontFamily: "Segoe UI",
            fontSize: "13px",
            fontWeight: "none",
            borderColor: "white",
            display: "flex",
            color: "#424242",
            alignItems: "center",
          }}
          onClick={handleFullScreenClick}
        >
          <ShareMultiple20Regular style={{ marginRight: "5px" }} />
          Full Window
        </DefaultButton>

        {is2D === "2D" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              iconProps={{ iconName: "Remove", color: "black" }}
              title="Decrease Value"
              ariaLabel="Decrease Value"
              onClick={() => handleZoomOut(0.1)} // minus
              styles={{
                root: { fontSize: "20px", color: "black", height: "20px" },
              }}
            />
            <div className="slider-container">
              <input
                type="range"
                min={0.0}
                max={2.0}
                step={0.1}
                value={twoDzoomLevel}
                onChange={handleSliderChange}
                className="slider"
                style={{ background: getGradientBackground() }} // create the green color area will follow the slider thumb move and increase
              />
            </div>

            <IconButton
              iconProps={{ iconName: "Add" }}
              title="Increase Value"
              ariaLabel="Increase Value"
              onClick={() => handleZoomIn(0.1)} // add
              styles={{ root: { fontSize: "20px", color: "black" } }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GraphBot;
