import React from "react";
import PropTypes from "prop-types";
const Share = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.3373 3.13951C10.6304 2.77988 11.0734 2.57612 11.5372 2.58762C11.9438 2.59771 12.2509 2.80148 12.4055 2.91154C12.5703 3.02886 12.758 3.18975 12.9441 3.3493C12.9532 3.35713 12.9623 3.36495 12.9714 3.37276L21.4422 10.6334C21.4486 10.6389 21.4552 10.6446 21.4618 10.6502C21.5662 10.7397 21.6875 10.8436 21.7853 10.9431C21.8959 11.0558 22.0507 11.2348 22.1434 11.4929C22.2611 11.8208 22.2611 12.1793 22.1434 12.5072C22.0507 12.7653 21.8959 12.9443 21.7853 13.057C21.6875 13.1565 21.5662 13.2604 21.4618 13.3499C21.4552 13.3556 21.4486 13.3612 21.4422 13.3667L12.9441 20.6508C12.758 20.8103 12.5704 20.9712 12.4055 21.0886C12.2509 21.1986 11.9438 21.4024 11.5372 21.4125C11.0734 21.424 10.6304 21.2202 10.3373 20.8606C10.0803 20.5453 10.0352 20.1795 10.0181 19.9905C9.99991 19.789 9.99995 19.5418 10 19.2967L10 16.1964C9.0578 16.3593 8.14001 16.6518 7.27329 17.0676C5.92197 17.7158 4.72673 18.6485 3.76944 19.8017C3.5003 20.1259 3.05681 20.2467 2.66047 20.1036C2.26413 19.9605 2 19.5844 2 19.163V18.551C2.00136 15.9303 2.96754 13.4013 4.71418 11.4474C6.1266 9.86748 7.9694 8.75275 10 8.22857V4.73942C10 4.72743 10 4.71542 10 4.7034C9.99995 4.45828 9.99991 4.2111 10.0181 4.00958C10.0352 3.82055 10.0803 3.45479 10.3373 3.13951ZM12 5.17427V9.03468C12 9.52048 11.6509 9.936 11.1723 10.0197C9.25852 10.3545 7.50691 11.3243 6.20524 12.7804C5.22531 13.8766 4.54774 15.1953 4.22184 16.6077C4.90026 16.087 5.63269 15.6364 6.40823 15.2643C7.82872 14.5829 9.36428 14.1792 10.9323 14.0729C11.2087 14.0541 11.4804 14.1508 11.6828 14.3399C11.8851 14.529 12 14.7936 12 15.0706V18.8258L19.9634 12L12 5.17427Z"
      clipRule="evenodd"
    />
  </svg>
);
Share.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default Share;
