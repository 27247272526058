// Navigation.js
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import PropTypes from "prop-types";
import "./Navigation.css";
import { useAppContext } from "../../../AppContext";
import { ChevronDownIcon } from "../../icons";
import userImage from "../../../image/user.png";
import { LogoutIcon, User, TeamIcon } from "../../icons/General";
import useAuthStore from "../../../stores/useAuthStore";

initializeIcons();

const Navigation = ({ menuItems, isLoggedIn, companyLogo, loginUser }) => {
  const { selectedMenuItem, setSelectedMenuItem } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { clearAuth } = useAuthStore();

  const handleClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    navigate(`/${menuItem}`);
  };

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (["discover", "drive", "achieve", "user-account"].includes(path)) {
      setSelectedMenuItem(path);
    }
  }, [setSelectedMenuItem, location]);

  const handleAccountClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownItemClick = (path) => {
    navigate(path);
    setSelectedMenuItem(path);
    setIsDropdownOpen(false);
  };

  // the logout function
  const handleLogOut = () => {
    clearAuth();
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav
      className={`navigation ${isLoggedIn ? "navigation-post-login" : "navigation-pre-login"}`}
    >
      <div className="navigation-content">
        <div className="navigation-left">
          {isLoggedIn && (
            <img
              src={companyLogo}
              alt="Company Logo"
              className="navigation-logo-pre-login"
            />
          )}

          {isLoggedIn && (
            <div className="menu-items">
              {menuItems.map((item) => (
                <button
                  key={item.key}
                  onClick={() => handleClick(item.key)}
                  className={`menu-item ${selectedMenuItem === item.key ? "selected" : ""}`}
                >
                  <div className="menu-item-content">
                    {item.icon}
                    <span>{item.name}</span>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="auth-buttons" ref={dropdownRef}>
          {isLoggedIn && (
            <div className="account-dropdown">
              <button
                className="button-account"
                onClick={handleAccountClick}
              >
                <img
                  src={userImage}
                  alt="User"
                  className="userLogo"
                  draggable="false"
                />
                  <ChevronDownIcon className={`arrow-icon ${isDropdownOpen ? 'open' : ''}`}/>
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <button onClick={() => handleDropdownItemClick("/user-account")} className="dropdown-item">
                    <User /> Profile
                  </button>
                  <button onClick={() => handleDropdownItemClick("/team")} className="dropdown-item">
                    <TeamIcon /> Team
                  </button>
                  <button onClick={handleLogOut} className="dropdown-Logout">
                    <LogoutIcon /> Log out
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  menuItems: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  companyLogo: PropTypes.string.isRequired,
  loginUser: PropTypes.object,
};

export default Navigation;
