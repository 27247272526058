import React from "react";
import { useAppContext } from "../../../AppContext";
import "./keySpecificationLayout.css";


const SpecificantionsFormat = ({ menuPage, specData }) => {
  const { highlightArray } = useAppContext();

  const filteredSpecs = specData.filter(
    (spec) => spec._source.subsystem === menuPage,
  );

  const parseKeywords = (keywords) => {
    if (typeof keywords === "string") {
      // Remove the single quotes and square brackets
      const cleanedString = keywords.replace(/^\[|\]$/g, "").replace(/'/g, '"');
      try {
        return JSON.parse(`[${cleanedString}]`);
      } catch (error) {
        console.error("Error parsing keywords:", error);
        return keywords.split(", ");
      }
    }
    return keywords;
  };

  return <>
    <div className="specificationsFormat">
      {filteredSpecs.map((spec) => (
        <div key={spec._id} className="specItem">
          
          <h3>{spec._source.title}</h3>

          <p dangerouslySetInnerHTML={{ __html: spec._source.description }} />
          <div style={{ display: "flex", marginTop: "16px" }}>
            <span className="docuItemContextGray" style={{ color: "#424242" }}>
              {" "}
              ID: {spec._source.id}{" "}
            </span>
          </div>
          {/*
                   
                    <div style={{ marginTop: "8px" }}>
                        <span className="docuItemContextGray">Keywords: </span>
                        {parseKeywords(spec._source.keywords).map((keyword, index) => (
                            <span key={index} className="keyword">{keyword}</span>
                        ))}
                    </div>
                    */}
        </div>
      ))}
    </div>
  </>
};

export { SpecificantionsFormat };
