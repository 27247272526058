// the is the page of Achieve module coming soon
import React, { useEffect, useState } from "react";
import "./ComeSoon.css";
import Buttoon from '../../Components/ui/Button/Button'
import Mail from "../../Components/icons/General/mail";
import AchieveImage from "../../image/AchieveImage.png"


const AchieveComeSoon = () => {
    const [isNoticeAdmin, setIstNoticeAdmin] = useState(false);

    useEffect(() => {
        const localNoticed = localStorage.getItem("noticedAchieve");
        setIstNoticeAdmin(localNoticed);
    }, [setIstNoticeAdmin]);

    // the button function: notice admin
    const noticeAdminButton = () => {
        setIstNoticeAdmin(true);
        localStorage.setItem("noticedAchieve", isNoticeAdmin);
    }

    return <div className="achieve-area">
        <div className="achieveContext">
            <div className="InfoSection">
                <h1>
                    Access <span className="highlight">transparent & trusted visibility of milestone progress</span> 
                </h1>
                <p>
                    Access to progress tracking, with insights into milestones based on live and historical program planning, issue,
                    tooling, PPAP and BOM data
                </p>
                <Buttoon
                    variant="primary"
                    size="small"
                    width="100%"
                    onClick={noticeAdminButton}
                > 
                    {isNoticeAdmin ? "Admin Notified" : "Notify admin"}
                </Buttoon>

                <div className="svgIcon"> <Mail size={24} color="white" /> </div>

                <div className="contact">
                    <p className="tittle">Contact us</p>
                    <p className="subTittle">We're here to help.</p>
                    <a href="mailto:Support@apiphany.ai" className="emailText">Support@apiphany.ai</a>
                </div>
            </div>

            <div className="DashboardImage">
                <img src={AchieveImage} alt="Achieve Module"/>
                <h3>Milestone progress analytics dashboard</h3>
                <ul>
                    <li>See your highest-risk areas and potential parts of failure for efficient resource allocation.</li>
                    <li>Gauge the health of your infrastructure.</li>
                    <li>Business case generation.</li>
                </ul>
            </div>
        </div>

    </div>

};

export default AchieveComeSoon;
