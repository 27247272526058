import React from "react";
import "./HorizontalTabs.css";
import PropTypes from "prop-types";
import classNames from "classnames";

// isActive: judges whether current status was active or not
const TabButton = ({ label, isActive, value, onClick, variant, isDisable = false}) => {
  const className = classNames(
    "tab-button",
    `tab-button--${variant}`,
    { "disabled": isDisable },
    { "active": isActive },
  );
  
  return (
    <button
      className={className}
      value={value}
      onClick={onClick}
      disabled={isDisable}
    >
      {label}
    </button>
  );
};

// Adding PropTypes for validation
TabButton.propTypes = {
  label: PropTypes.string.isRequired, // label should be a string and is required
  isActive: PropTypes.bool, // isActive should be a boolean
  onClick: PropTypes.func.isRequired, // onClick should be a function and is required
  value: PropTypes.string,
  variant: PropTypes.string,
  isDisable: PropTypes.bool, // the disbale PropTypes 
};

export default TabButton;
