import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Badge.module.css";
import CloseIcon from "../../icons/CloseIcon";

const Badge = ({
  type = "default",
  variant,
  children,
  icon,
  onClick,
  onContextMenu,
  onEdit,
  disabled = false,
  className,
  innerRef,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(children);
  const [displayValue, setDisplayValue] = useState(children);
  const inputRef = useRef(null);

  useEffect(() => {
    setEditValue(children);
    setDisplayValue(children);
  }, [children]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const trimmedValue = editValue.trim();
      setIsEditing(false);
      if (onEdit && trimmedValue !== displayValue) {
        onEdit(trimmedValue);
        setEditValue(displayValue);
      }
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditValue(displayValue);
    }
    e.stopPropagation();
  };

  const handleBlur = () => {
    setIsEditing(false);
    setEditValue(displayValue);
  };

  // Expose methods through ref
  useEffect(() => {
    if (innerRef) {
      innerRef.current = {
        startEditing: () => setIsEditing(true)
      };
    }
  }, [innerRef]);

  const badgeClass = `
    ${styles.badge}
    ${styles[`badge--${type}`]}
    ${styles[`badge--${variant}`]}
    ${disabled ? styles["badge--disabled"] : ""}
    ${className || ""}
  `.trim();

  const content = (
    <>
      {type === "status" && (
        <span
          className={`${styles.badge__status_dot} ${styles[`badge__status_dot--${variant}`]}`}
        ></span>
      )}
      {icon && <span className={styles.badge__icon}>{icon}</span>}
      {isEditing ? (
        <input
          ref={inputRef}
          className={styles.badge__input}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <span className={styles.badge__text}>{displayValue}</span>
      )}
      {(type === "input" || type === "inputWhite") && (
        <button className={styles.badge__close} onClick={onClick}>
          <CloseIcon size={16} color="var(--color-gray-800)" />
        </button>
      )}
    </>
  );

  if (type === "input") {
    return (
      <div className={badgeClass} {...props}>
        {content}
      </div>
    );
  }

  if (type === "inputWhite") {
    return (
      <div className={badgeClass} {...props}>
        {content}
      </div>
    );
  }

  if(type === "default"){
    return (
      <div 
        className={badgeClass} 
        onClick={!isEditing ? onClick : undefined}
        onContextMenu={onContextMenu}
        {...props}
      >
        {content}
      </div>
    );
  }

  return (
    <span className={badgeClass} {...props}>
      {content}
    </span>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(["status", "default", "input", "highlight", "file"]),
  variant: PropTypes.oneOf([
    "default",
    "areaDefault",
    "Current",
    "Closed",
    "Open",
    "Other",
    "hovered",
    "selected",
    "issue",
    "keyword",
    "failure-mode",
    "components",
    "failure-condition",
    "after-effects",
    "specifications",
  ]),
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onEdit: PropTypes.func,
  innerRef: PropTypes.shape({ current: PropTypes.any }),
};

export default Badge;
