// This is search field component with tag menu: The tag menu is highest level with input search to search the relative issue first
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./SearchField.module.css";
import { Badge } from "../Badge";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../DropdownMenu/DropdownMenu";
import { Close } from "../../icons/General";
import { useEffect } from "react";
import Button from "../Button/Button";

// judge the "Add All" checkbox display checked mark or indeterminate mark ==> based on how many item user selcted
const getSelectionState = (filters) => {
  const checkedCount = filters.filter((f) => f.checked).length;
  return {
    checked: checkedCount > 0 && checkedCount <= filters.length,
    indeterminate: checkedCount > 0 && checkedCount < filters.length,
  };
};

// make first character as big character
const formatString = (str) => {
  return str
    .split('-') // split the words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // each character with bigger character
    .join(' '); // use the space connect
};


export default function SearchField({
  placeholder = "Search...", // Setting a default value here
  onSearch,
  initialTerms = [], // Setting a default value here
  filterOptions = {
    status: [],
    system: [],
    requestor: [],
    stages: [],
    areas: [],
  },
  setSelectedAreas,
  setSelectStatus,
  basicValue = [],
}) {
  const [searchTerms, setSearchTerms] = useState(initialTerms); // the tag that user added it
  const [inputValue, setInputValue] = useState(""); // the user input string of search field
  const inputRef = useRef(null); // judge the scroll effect of search field input value area
  const searchFieldRef = useRef(null); // the search field ref
  const dropdownRef = useRef(null); // the filter dropdown menu ref
  const [activeMenu, setActiveMenu] = useState("status"); // the default page of filter menu open
  const [open, setOpen] = useState(false); 
  const searchTermsRef = useRef(null); // the search field tags ref

  const [filters, setFilters] = useState({});
  const [statusArr, setStatusArr] = useState([]);
  const [areaArr, setAreaArr] = useState([]);

  useEffect(() => {
    if(basicValue && basicValue.length > 0){
      setSearchTerms(basicValue);
    }
  }, [basicValue]);
  
  
  // the filter menu show up filter selection 
  useEffect(() => {
    if (
      filterOptions &&
      Object.values(filterOptions).some((arr) => arr.length > 0)
    ) {
     
      const newFilters = Object.entries(filterOptions).reduce(
        (acc, [key, values]) => {
          acc[key] = (values || [])
            .filter((value) => value != null && value !== "")
            .map((value) => ({
              id: `${key}-${value.toString().toLowerCase().replace(/\s+/g, "-")}`,
              label: value,
              checked: false,
            }));
          return acc;
        },
        {},
      );

      setFilters(newFilters);
    }
  }, [filterOptions]);

  // the dropdown menu of search field filter menu layout and style
  useEffect(() => {
    if (searchFieldRef.current && dropdownRef.current) {
      const searchFieldRect = searchFieldRef.current.getBoundingClientRect();
      dropdownRef.current.style.width = `${searchFieldRect.width}px`;
      dropdownRef.current.style.left = `${searchFieldRect.left}px`;
      dropdownRef.current.style.top = `${searchFieldRect.bottom}px`;
    }
  }, []);

  // search field user input function 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // the function used for user input tags
  const handleInputKeyDown = (e) => {
    if (e.key === "," && inputValue.trim()) {
      e.preventDefault(); // Prevent comma from being typed
      const isNumber = !isNaN(Number(inputValue));
      const newTerm = {
        id: Date.now().toString(),
        value: inputValue.trim(),
        isIssueId: isNumber,
      };
      const updatedTerms = [...searchTerms, newTerm];
      setSearchTerms(updatedTerms);
      setInputValue("");
      onSearch(e, updatedTerms);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (inputValue.trim()) {
        // If there's text in the input, add it as a term first
        const isNumber = !isNaN(Number(inputValue));
        const newTerm = {
          id: Date.now().toString(),
          value: inputValue.trim(),
          isIssueId: isNumber,
        };
        const updatedTerms = [...searchTerms, newTerm];
        setSearchTerms(updatedTerms);
        setInputValue("");
        onSearch(e, updatedTerms, true); // Pass true to indicate Enter was pressed
      } else {
        // If input is empty, just trigger search with existing terms
        onSearch(e, searchTerms, true);
      }
    }
  };

  // the function used for remove the tags of badges close icon
  const removeTerm = (id) => {
    const updatedTerms = searchTerms.filter((term) => term.id !== id);
    if (id.startsWith("filter-status-status-")) { // the status
      const filterId = id.replace("filter-status-", "");
      setFilters((prev) => ({
        ...prev,
        status: prev.status.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));
      // based on user remove tag badges to change our issue data table
      const removeID = formatString(filterId.replace("status-", "")); //change the id as request format
      handleStatusChange(removeID, "clear one"); // change the issue data table

    } else if (id.startsWith("filter-system-system-")) { // the system
      const filterId = id.replace("filter-system-", ""); 
      setFilters((prev) => ({
        ...prev,
        system: prev.system.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));

      // based on user remove tag badges to change our issue data table
      const removeID = formatString(filterId.replace("system-", "")); //change the id as request format
      handleAreaChange(removeID, "clear one"); // change the issue data table

    } else if (id.startsWith("filter-requestor-requestor-")) { // the issue owner
      const filterId = id.replace("filter-requestor-", "");
      setFilters((prev) => ({
        ...prev,
        requestor: prev.requestor.map((filter) =>
          filter.id === filterId ? { ...filter, checked: false } : filter,
        ),
      }));
    }

    setSearchTerms(updatedTerms);
    onSearch(updatedTerms);
  };

  // the clear icon of search field: remove all the badges item
  const clearAllTerms = () => {
    setSearchTerms([]);
    onSearch([]);
    inputRef.current?.focus();
  };


  // Save all the user clicked Status item of dropdown list
  const handleStatusChange = (status, action) => {
    if (action === "clearAll") { //clear all
      setStatusArr([]); 
    } else if(action === "add one"){ // add one
      setStatusArr((prev) =>
        prev.includes(status)
          ? prev.filter((a) => a.toLowerCase() !== status.toLowerCase())
          : [...prev, status],
      ); 
    } else if(action === "clear one"){
      setStatusArr((prev) => prev.filter((a) => a.toLowerCase() !== status.toLowerCase()));
    } else{ // add all
      setStatusArr((prev) => [...prev, status]);
    }
  };
  useEffect(() => {
    setSelectStatus(statusArr);
  }, [setSelectStatus, statusArr]);


  // Save all the user clicked area item of dropdown list
  const handleAreaChange = (area, action) => {
    if(action === "clearAll"){
      setAreaArr([]);
    } else if(action === "add one"){
      setAreaArr((prev) =>
        prev.includes(area) ? prev.filter((a) => a.toLowerCase() !== area.toLowerCase()) : [...prev, area],
      );
    } else if(action === "clear one"){
      setAreaArr((prev) => prev.filter((a) => a.toLowerCase() !== area.toLowerCase()));
    } else{
      setAreaArr((prev) => [...prev, area]);
    }
  };
  useEffect(() => {
    setSelectedAreas(areaArr);
  }, [setSelectedAreas, areaArr]);


  // the filter drop down menu of search field: user could select the filter tags
  const handleFilterChange = (type, id) => {

    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [type]: prev[type].map((filter) => {
          if (filter.id === id) {
            const newChecked = !filter.checked;
            if (newChecked) {
              setSearchTerms((prevTerms) => {
                const newTerms = [
                  ...prevTerms,
                  {
                    id: `filter-${type}-${filter.id}`,
                    value: filter.label,
                    isIssueId: false,
                  },
                ];
                onSearch(newTerms);
                return newTerms;
              });
            } else {
              setSearchTerms((prevTerms) => {
                const newTerms = prevTerms.filter(
                  (term) => term.id !== `filter-${type}-${filter.id}`,
                );
                onSearch(newTerms);
                return newTerms;
              });
            }
            
            if(type === 'status'){
              handleStatusChange(filter.label, "add one");
            } else if(type === 'system'){
              handleAreaChange(filter.label, "add one");
            }

            return { ...filter, checked: newChecked };
          }
          return filter;
        }),
      };
      return newFilters;
    });
  };

  const handleSelectAll = (type) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [type]: prev[type].map((filter) => {
          if (!filter.checked) {
            setSearchTerms((prevTerms) => {
              const newTerms = [
                ...prevTerms,
                {
                  id: `filter-${type}-${filter.id}`,
                  value: filter.label,
                  isIssueId: false,
                },
              ];
              onSearch(newTerms);
              if(type === 'status'){
                handleStatusChange(filter.label, "add all");
              } else if(type === 'system'){
                handleAreaChange(filter.label, "add all");
              }
              return newTerms;
            });
          }
          return { ...filter, checked: true };
        }),
      };
      return newFilters;
    });
  };

  // the filter menu "Clear All" Button function: remove the single page tags
  const handleClearAll = (type) => {
    setFilters((prev) => ({
      ...prev,
      [type]: prev[type].map((filter) => ({ ...filter, checked: false })),
    }));

    setSearchTerms((prevTerms) => {
      const newTerms = prevTerms.filter(
        (term) => !term.id.startsWith(`filter-${type}-`),
      );
      onSearch(newTerms);
      if(type === 'status'){
        handleStatusChange("clear all", "clearAll");
      } else if(type === 'system'){
        handleAreaChange("clear all", "clearAll");
      }
      return newTerms;
    });
  };

  // based on the searchTerms total length, will have scroll effect
  useEffect(() => {
    if (searchTermsRef.current) {
      const element = searchTermsRef.current;
      const parent = element.parentElement;
      parent.scrollTo({
        left: element.scrollWidth,
        behavior: "smooth",
      });
    }
  }, [searchTerms]);

  // if did not have any data, display "No content available"
  const renderFilterContent = () => {
    if (!filters[activeMenu]) {
      return (
        <div className={styles.filterMenuSubContent}>
          <div>No content available</div>
        </div>
      );
    }

    const { checked, indeterminate } = getSelectionState(filters[activeMenu]);

    return (
      <div className={styles.filterMenuSubContent}>
        <div className={styles.filterMenuFixedTop}>
          <DropdownMenuCheckboxItem
            checked={checked}
            onClick={(e) => {
              e.preventDefault();
              handleSelectAll(activeMenu);
            }}
            indeterminate={indeterminate}
          >
            Select all
          </DropdownMenuCheckboxItem>
        </div>
        <DropdownMenuSeparator />
        <div className={styles.filterMenuScrollable}>
          {filters[activeMenu].map((filter) => (
            <DropdownMenuCheckboxItem
              key={filter.id}
              checked={filter.checked}
              onClick={(e) => {
                e.preventDefault();
                handleFilterChange(activeMenu, filter.id);
              }}
            >
              {activeMenu === "status" ? (  
                
                <Badge type="status" variant={(filter.label === "Closed" || filter.label === "Open" || filter.label === "Current") ? filter.label : "Other"}>{filter.label}</Badge>
              ) : (
                filter.label
              )}
            </DropdownMenuCheckboxItem>
          ))}
        </div>
        <DropdownMenuSeparator />
        <div className={styles.filterMenuFixedBottom} style={{ marginLeft: "2px" }}>
          <Button variant="danger-link" size="medium"
            onClick={(e) => {
              e.preventDefault();
              handleClearAll(activeMenu);
            }}
          >
            Clear all
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.searchField} ref={searchFieldRef}>
      <div style={{display: "flex", alignItems: "center", flex: '1', height: "32px"}}>

        <div className={styles.searchTermsContainer}>
          <div className={styles.searchTerms} ref={searchTermsRef}>
            {searchTerms.map((term) => (
              <Badge
                key={term.id}
                type="input"
                variant="default"
                onClick={() => removeTerm(term.id)}
              >
                {term.isIssueId ? `issue id: ${term.value}` : term.value}
              </Badge>
            ))}
          </div>
        </div>

        <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            className={styles.searchInput}
            placeholder={placeholder}
        />
      </div>

      <div className={styles.searchActions}>
        <div>
          {searchTerms.length > 0 && (
            <button onClick={clearAllTerms} className={styles.clearButton}>
              <Close size={16} className={styles.clearIcon} />
            </button>
          )}
        </div>
        <DropdownMenu open={open} onOpenChange={setOpen}>
          {searchTerms.length > 0 && <DropdownMenuTrigger filterIcon>
            <span className={styles.dropdownMenuTriggerText}>Filters</span>
          </DropdownMenuTrigger> }
          
          <DropdownMenuContent
            className={styles.filterMenuContent}
            filterIcon
            align="end"
            alignOffset={-14}
            sideOffset={8}
          >
            <div className={styles.filterMenuContainer}>
              <div className={styles.filterMenuMain}>
                <DropdownMenuSub defaultOpen>
                  {Object.keys(filters).map((filterType) => (
                    <DropdownMenuSubTrigger
                      key={filterType}
                      filterIcon
                      onClick={() => setActiveMenu(filterType)}
                      data-active={activeMenu === filterType}
                    >
                      <span className="filterMenuSubContentItem">
                        {filterType.charAt(0).toUpperCase() +
                          filterType.slice(1)}
                      </span>
                    </DropdownMenuSubTrigger>
                  ))}
                </DropdownMenuSub>
              </div>

              <div className={styles.filterMenuSub}>
                {renderFilterContent()}
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

SearchField.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  initialTerms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isIssueId: PropTypes.bool.isRequired,
    }),
  ),
  filterOptions: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string),
    system: PropTypes.arrayOf(PropTypes.string),
    requestor: PropTypes.arrayOf(PropTypes.string),
    stages: PropTypes.arrayOf(PropTypes.string),
    areas: PropTypes.arrayOf(PropTypes.string),
  }),
  setSelectedAreas: PropTypes.func,
  setSelectStatus: PropTypes.func,
  basicValue: PropTypes.array,
};
