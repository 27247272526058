// this is the similar issue details componment (windows area) to display the issue slected details
import React, { useState, useCallback } from "react";
import { useAppContext } from "../../../AppContext";
import { generateDynamicStyles } from "../dynamicStyles";
import ResizableDraggablePopup from "./ResizableDraggablePopup";
import { Badge, TabButton, Button } from "../../../Components";
import { DocxIcon, PdfIcon } from "../../../Components/icons/General";

const IssueDetails = () => {
  const { clickedSimilarIssue, setClickedSimilarIssue, highlightArray, isOpenSpecification } =
    useAppContext();
  const [detailsMenu, setDetailsMenu] = useState("Details");
  const [size, setSize] = useState({ width: 1100, height: "auto" });

  // the cancel button and closed button for closed pop window
  const closeArea = () => {
    setClickedSimilarIssue(null);
  };

  // control the menu format in the Properties area switch
  const switchMenu = useCallback(
    (e) => {
      setDetailsMenu(e.target.value);
    },
    [setDetailsMenu],
  );

  // issueDetailsModal have two insildes function => click mouse to update the new position / relase mosue to stop dragging
  return (
    <div>
      {/* Injecting dynamic styles */}
      <style>
        {generateDynamicStyles(
          highlightArray["Failure Mode"],
          highlightArray["Components"],
          highlightArray["Failure Condition"],
          highlightArray["After Effects"],
          highlightArray["Specification"],
        )}
      </style>

      {/*issueDetailsArea  => click the mouse to start dragging the pop window / and update the position based on the Top and left attribute  */}
      <ResizableDraggablePopup
        onClose={closeArea}
        size={size}
        setSize={setSize}
        isOpenArea={isOpenSpecification}
      >
        <div className="detailsHeader">
          <Badge type={"status"} variant={(clickedSimilarIssue["Status"] === "Closed" || clickedSimilarIssue["Status"] === "Open" || clickedSimilarIssue["Status"] === "Current") ? clickedSimilarIssue["Status"] : "Other"} children={clickedSimilarIssue["Status"]} />
          <h2>Issue ID: {clickedSimilarIssue["Issue ID"]} </h2>
        </div>

        <div className="realativeIssueDetailsMenu">
          <TabButton
            label={"Details"}
            value={"Details"}
            variant={"black"}
            isActive={detailsMenu === "Details" ? true : false}
            onClick={(e) => switchMenu(e)}
          />
          <TabButton
            label={"Timeline"}
            value={"Timeline"}
            variant={"black"}
            isActive={detailsMenu === "Timeline" ? true : false}
            isDisable={true}
            onClick={(e) => switchMenu(e)}
          />
          <TabButton
            label={"Comments"}
            value={"Comments"}
            variant={"black"}
            isActive={detailsMenu === "Comments" ? true : false}
            isDisable={true}
            onClick={(e) => switchMenu(e)}
          />
        </div>

        <div style={{ maxHeight: "600px", overflowY: "auto", width: "100%"}}>
          {detailsMenu === "Details" && (
            <div className="issueFormat">
              <div className="issueFormatChild" style={{ marginRight: "4px" }}>

                <div className="issueDetailsRowDisplay">
                  <h3>Date: </h3> {clickedSimilarIssue["When"].split("T")[0]}
                  <h3 style={{ marginLeft: "16px" }}>Case: </h3> {clickedSimilarIssue["Cases"]}
                </div>

                <div className="issueDetailsColumnDisplay">
                  <h2>Issue Summary </h2>
                  {clickedSimilarIssue ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: clickedSimilarIssue["Issue Details"],
                      }}
                    />
                  ) : (
                    " "
                  )}
                </div>

                <div className="issueDetailsColumnDisplay">
                  <h2>Area </h2>
                  <div className="detailsAreaValue">
                    {clickedSimilarIssue && clickedSimilarIssue["AreaList"]
                      ? clickedSimilarIssue["AreaList"].map((area, index) => (
                        <span key={index} className="areaItem">
                          {area}
                        </span>
                      ))
                      : clickedSimilarIssue["Area"]
                        ? clickedSimilarIssue["Area"]
                          .split(", ")
                          .map((area, index) => (
                            <span key={index} className="areaItem">
                              {area}
                            </span>
                          ))
                        : " "
                    }
                  </div>

                  <div style={{ display: "flex" }}><h3> Part Number: </h3> {clickedSimilarIssue["Part Number"]}</div>
                </div>

                <div className="issueDetailsColumnDisplay">
                  <h2>Failure mode</h2>
                  {clickedSimilarIssue ? clickedSimilarIssue["Failure Mode"] : " "}
                </div>

                <div className="issueDetailsRowDisplay">
                  <h3>Owner: </h3> {clickedSimilarIssue ? clickedSimilarIssue["Requestor"] : " "}
                </div>

              </div>

              <div className="issueFormatChild">
                <div className="issueDetailsColumnDisplay">
                  <h2>Resolutions</h2>
                  {clickedSimilarIssue["Resolution"] ? clickedSimilarIssue["Resolution"] : "Temporary did not have a Resolution"}
                </div>
              </div>

              <div className="issueDetailsColumnDisplay">
                <h2>Financial Impact</h2>
                {clickedSimilarIssue["Quantitative Impact"] ? clickedSimilarIssue["Quantitative Impact"] : "N/A"}
              </div>

              <div className="issueDetailsColumnDisplay">
                <div style={{ display: "flex", alignItems: "center" }}><h2>Attachments</h2> <p className="downloadText">Download all</p></div>
                <div className="attachmentsArea">
                  <Badge type="file" variant="default" icon={<DocxIcon/>}>
                    Resolutions 6/05.docx
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="issueDetailsWindowButtonArea">
          <Button variant="primary" size="medium" onClick={closeArea}> Inspect </Button>
        </div>
      </ResizableDraggablePopup>
    </div>
  );
};

export default IssueDetails;
