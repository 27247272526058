import React from "react";
import PropTypes from "prop-types";

const StarIcon = ({
  size = 24,
  color = "var(--icon-color)",
  isStarred,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={isStarred ? "var(--warning-200)" : "none"}
    stroke={isStarred ? "var(--warning-400)" : color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

StarIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStarred: PropTypes.bool,
};

export default StarIcon;
