import React, { useState } from "react";
import { Button } from "./index";
import "./ShareEmailWindow.css";

const ShareEmailWindow = ({ selectedCount, onShare, onClose }) => {
  const [selectedEmails, setSelectedEmails] = useState([]);
  
  const emails = [
    "mat.khajavi@apiphany.ai",
    "rebecca@apiphany.ai",
    "ben.larue@apiphany.ai"
  ];

  const handleEmailToggle = (email) => {
    setSelectedEmails(prev => {
      if (prev.includes(email)) {
        return prev.filter(e => e !== email);
      }
      return [...prev, email];
    });
  };

  return (
    <div className="emailShareModal">
      <div className="emailShareContent">
        <h3>Share with ({selectedCount} issues)</h3>
        <div className="emailList">
          {emails.map(email => (
            <label key={email} className="emailOption">
              <input
                type="checkbox"
                checked={selectedEmails.includes(email)}
                onChange={() => handleEmailToggle(email)}
              />
              {email}
            </label>
          ))}
        </div>
        <div className="modalActions">
          <Button 
            variant="secondary" 
            size="medium" 
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            size="medium" 
            onClick={() => {
              onShare(selectedEmails);
              onClose();
            }}
            disabled={selectedEmails.length === 0}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShareEmailWindow; 