// Reusebale Library: the button component (old one)
import React from "react";
import PropTypes from "prop-types";
//import './Button.css';

const ButtonOld = ({
  label,
  onClick,
  className,
  style,
  icon,
  iconPosition = "left",
  iconStyle,
}) => (
  <button className={className} onClick={onClick} style={style}>
    {/* 1. the icon in the left of label text */}
    {icon && iconPosition === "left" && label && (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "2px",
          ...iconStyle,
        }}
      >
        {icon}
      </span>
    )}
    {label}

    {/* 2. the icon in the right of label text */}
    {icon && iconPosition === "right" && label && (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "4px",
          ...iconStyle,
        }}
      >
        {icon}
      </span>
    )}

    {/* 3. only have icon, the center of area */}
    {!label && (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...iconStyle,
        }}
      >
        {icon}
      </span>
    )}
  </button>
);

ButtonOld.propTypes = {
  label: PropTypes.string.isRequired, // label should be string
  onClick: PropTypes.func, // onclick should be a function
  className: PropTypes.string, // the layout of this button: color, background, border, size
  style: PropTypes.object, // the other layout if have
  icon: PropTypes.element, // option: the icon in the button
  iconPosition: PropTypes.oneOf(["left", "right"]), // the icon of button should be the right or left
  iconStyle: PropTypes.object, // the icon color style
};

export default ButtonOld;
