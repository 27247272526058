// When the website screen page less than 1280px, it will display and notice user increase the screen width
import React from "react";
import './MiniumWidth.css';
import { Maximize } from "../../../Components/icons/Arrows";
import { useLocation } from "react-router-dom";

const MiniumWidth = () => {

    const location = useLocation(); 

    if (location.pathname === "/login") {
        return null;
    }

    return <div className="warning-overlay">
        <div className="warning-message">
            <div className="IncreaseIcon">
                <Maximize size={28} color="var(--danger-600)"/>
            </div>
            <div>
                <h2>Your browser is too small</h2>
                <p>Resize your browser to be set at least 1024px wide to get back into work.</p>
            </div>
        </div>
    </div>
}

export default MiniumWidth;
