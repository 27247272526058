import React from "react";
import PropTypes from "prop-types";

const TeamIcon = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 4C7.84315 4 6.5 5.34315 6.5 7C6.5 8.65685 7.84315 10 9.5 10C11.1569 10 12.5 8.65685 12.5 7C12.5 5.34315 11.1569 4 9.5 4ZM4.5 7C4.5 4.23858 6.73858 2 9.5 2C12.2614 2 14.5 4.23858 14.5 7C14.5 9.76142 12.2614 12 9.5 12C6.73858 12 4.5 9.76142 4.5 7ZM14.5731 2.91554C14.7803 2.40361 15.3633 2.1566 15.8752 2.36382C17.7058 3.10481 19 4.90006 19 7C19 9.09994 17.7058 10.8952 15.8752 11.6362C15.3633 11.8434 14.7803 11.5964 14.5731 11.0845C14.3658 10.5725 14.6129 9.98953 15.1248 9.7823C16.2261 9.33652 17 8.25744 17 7C17 5.74256 16.2261 4.66348 15.1248 4.2177C14.6129 4.01047 14.3658 3.42748 14.5731 2.91554ZM7.96448 14H11.0355C11.9373 14 12.6647 14 13.2567 14.0404C13.8654 14.0819 14.4037 14.1695 14.9134 14.3806C16.1386 14.8881 17.1119 15.8614 17.6194 17.0866C17.8305 17.5963 17.9181 18.1346 17.9596 18.7433C18 19.3353 18 20.0627 18 20.9645V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.0544 15.9995 19.3953 15.9643 18.8794C15.9297 18.3725 15.865 18.0773 15.7716 17.8519C15.4672 17.1169 14.8831 16.5328 14.1481 16.2284C13.9227 16.135 13.6275 16.0703 13.1206 16.0357C12.6047 16.0005 11.9456 16 11 16H8C7.05444 16 6.39534 16.0005 5.87945 16.0357C5.37254 16.0703 5.07733 16.135 4.85195 16.2284C4.11687 16.5328 3.53284 17.1169 3.22836 17.8519C3.135 18.0773 3.07033 18.3725 3.03574 18.8794C3.00054 19.3953 3 20.0544 3 21C3 21.5523 2.55229 22 2 22C1.44772 22 1 21.5523 1 21L1 20.9645C0.999993 20.0627 0.999988 19.3353 1.04038 18.7433C1.08191 18.1346 1.16948 17.5963 1.3806 17.0866C1.88807 15.8614 2.86144 14.8881 4.08658 14.3806C4.59628 14.1695 5.13456 14.0819 5.74331 14.0404C6.33531 14 7.06272 14 7.96448 14ZM18.0316 14.8768C18.1692 14.3419 18.7144 14.0199 19.2493 14.1576C21.4056 14.7126 23 16.6688 23 19V21C23 21.5523 22.5523 22 22 22C21.4477 22 21 21.5523 21 21V19C21 17.6035 20.0449 16.4275 18.7507 16.0945C18.2159 15.9568 17.8939 15.4116 18.0316 14.8768Z"
      clipRule="evenodd"
    />
  </svg>
);
TeamIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default TeamIcon;