import { useMutation } from "react-query";
import api from "./config";
import useAuthStore from "../stores/useAuthStore";

// the logIn feature ==> call API 
export const useLoginMutation = () => {
  return useMutation(async (credentials) => {
    const response = await api.post("/token", credentials);
    return response.data;
  });
};

// the search relative issue api
export const useSearchMutation = () => {
  return useMutation(async (ticketId) => {
    try {
      const token = useAuthStore.getState().token;
      
      const response = await api.post(
        "search",
        { query: ticketId },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      return response.data;
    } catch (error) {
      // Log the full error response for debugging
      if (error.response) {
        console.error('Search error response:', error.response.data);
      }
      console.error('Search error:', error);
      throw error;
    }
  });
};

// the star icon (favorite) function connect backend
export const useToggleFavoriteMutation = () => {
  return useMutation(async ({ issueId, sourceId, isFavorite }) => {
    const token = useAuthStore.getState().token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (isFavorite) {
      const response = await api.delete("/favorites", {
        ...config,
        data: { issue_id: issueId, source_id: sourceId },
      });
      return response.data;
    } else {
      const response = await api.post(
        "/favorites",
        { issue_id: issueId, source_id: sourceId },
        config,
      );
      return response.data;
    }
  });
};

// the update password api function
export const useUpdatePasswordMutation = () => {
  return useMutation(async (passwordData) => {
    const token = useAuthStore.getState().token;
    const response = await api.post("/update-password",
      {
        current_password: passwordData.currentPassword,
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Add this to src/api/queries.js
export const useShareIssuesMutation = () => {
  return useMutation(async ({ emails, shareUrl }) => {
    if (!emails?.length || !shareUrl) {
      throw new Error('Invalid share parameters');
    }
    try {
      const token = useAuthStore.getState().token;
      
      // First get CSRF cookie
      await api.get('/sanctum/csrf-cookie', {
        withCredentials: true,
        baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const response = await api.post(
        "/share",
        { 
          emails: Array.isArray(emails) ? emails : [emails],
          share_url: shareUrl
        },
        {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'X-XSRF-TOKEN': document.cookie.match(/XSRF-TOKEN=([\w-]+)/)?.[1]
          }
        }
      );
      
      if (response.data?.redirect_url) {
        window.location.href = response.data.redirect_url;
        return;
      }
      
      return response.data;
      
    } catch (error) {
      if (error.response?.status === 419) {
        // CSRF token mismatch, try to refresh the token
        try {
          await api.get('/sanctum/csrf-cookie', {
            withCredentials: true,
            baseURL: process.env.REACT_APP_API_URL
          });
          // Retry the original request
          return await api.post("/share", {
            emails: Array.isArray(emails) ? emails : [emails],
            share_url: shareUrl
          });
        } catch (retryError) {
          console.error('Error refreshing CSRF token:', retryError);
          throw retryError;
        }
      }
      console.error('Share error:', error);
      throw error;
    }
  });
};

// Add this new mutation
export const useUpdateIssueMutation = () => {
  return useMutation(async ({ issueId, field, value }) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "/update-issue",
      { 
        issue_id: issueId,
        field: field,
        value: value 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};
