//the setup page
import React, { useEffect } from "react";
import { useAppContext } from "../../AppContext";

const Setup = () => {
  const { setSelectedMenuItem } = useAppContext();

  useEffect(() => {
    setSelectedMenuItem("setup");
  }, [setSelectedMenuItem]);

  return <div></div>;
};

export default Setup;
