import { create } from "zustand";
import { persist } from "zustand/middleware";

const useAuthStore = create(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      token: null,
      setAuth: (userData, token) =>
        set({ isAuthenticated: true, user: userData, token }),
      clearAuth: () => set({ isAuthenticated: false, user: null, token: null }),
    }),
    {
      name: "auth-storage",
      getStorage: () => localStorage,
    },
  ),
);

export default useAuthStore;
