// the custom component for the event of error handling for failed queries
import React, { useCallback, useState } from "react";
import { Checkbox } from "@fluentui/react";
import { useAppContext } from "../../../../AppContext";
import PrintSelection from "../../../../Components/icons/OldIcon/PrintSelection";
import {
  ChevronDown20Regular,
  ChevronUp20Regular,
  DismissFilled,
} from "@fluentui/react-icons";
import jsPDF from "jspdf";
import "./printLayout.css";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

/* used for removed the blue border when selected the checkbox of Highlights drop down list area */
const checkboxStyles = mergeStyleSets({
  root: {
    selectors: {
      "& .ms-Checkbox": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox:hover": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox-checkbox": {
        borderColor: "#616161 !important",
      },
      "& .ms-Checkbox-checkbox:hover": {
        borderColor: "#616161 !important",
      },
    },
  },
});

// custom component: AccordionItem for selection part: Basic Deteils, change action evaluation and business case evaluation, and release notice
const AccordionSelectionItem = ({
  title,
  isOpen,
  onClick,
  children,
  isChecked,
  onCheckboxChange,
}) => (
  <div className="printSelectionArea">
    <div
      className={`printSelectionContainer ${isOpen ? "active" : ""}`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={isChecked} // based on the children item selection
          onChange={onCheckboxChange} // follow the change
          styles={{
            root: checkboxStyles.root,
            checkbox: {
              width: "14px",
              height: "14px",
              marginRight: "28px",
              borderColor: "#616161",
            },
            checkmark: {
              background: "#066755",
              color: "white",
              fontWeight: "bold",
            },
            label: {
              display: "flex",
              alignItems: "center",
            },
          }}
        />
        {title}
      </div>
      {isOpen ? (
        <ChevronUp20Regular style={{ color: "##242424" }} />
      ) : (
        <ChevronDown20Regular style={{ color: "##242424" }} />
      )}
    </div>
    {isOpen && (
      <div className={`printSelectionContext ${isOpen ? "active" : ""}`}>
        {children}
      </div>
    )}
  </div>
);

// (text, x-position, y-position)
const PrintModal = ({ onDismiss, concernData }) => {
  const {
    clickNode,
    selectedSections,
    setSelectedSections,
    selectedScenario,
    setSelectedScenario,
  } = useAppContext();

  // the state used for open multiple print selection item
  const [openSection, setOpenSection] = useState({
    "Basic Details": false,
    "Change Action": false, // Change Action Evaluation
    "Business Case": false, // Business Case Evaluation
    "Release Notice": false, // Release Notice
  });

  const [position, setPosition] = useState({ x: 0, y: 0 }); // the position of pop window
  const [dragging, setDragging] = useState(false); // whether is dargging of pop window or not
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 }); // the offset of the mouse click position relative to the current position of the window

  // when user use mouse click the pop window area, it will called => means started dragging
  // amd also  Calculate the offset of the mouse click position relative to the current position of the pop window
  const startDrag = (e) => {
    setDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // update the new position of pop window
  const onDrag = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      });
    }
  };

  // when the user releases the mouse, it will happned ==> stop dragging
  const endDrag = () => setDragging(false);

  // the function used for open multiple print selection item
  const toggleSection = (section) => {
    setOpenSection((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  //check whether children side have one small item was selected it
  const isAllSelected = (section) => {
    return Object.values(selectedSections[section]).some(
      (value) => value === true,
    );
  };

  // the parents select happened change ==>
  const handleParentCheckboxChange = (section) => {
    const allSelected = isAllSelected(section);
    // if selected, all the children selection will be true, / if unselected, all the children selection will be false
    const updatedSection = Object.keys(selectedSections[section]).reduce(
      (acc, subSection) => {
        acc[subSection] = !allSelected;
        return acc;
      },
      {},
    );

    setSelectedSections({
      ...selectedSections,
      [section]: updatedSection,
    });
  };

  // the selected function ==> that user colud select print which parts
  const handleCheckboxChange = (section, subSection) => {
    setSelectedSections((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [subSection]: !prevState[section][subSection],
      },
    }));
  };

  // the selected function about Scenario 1 2 3 of concern flow stage "Change action Evaluation" and "Business Case Evaluation"
  const handleSelectedScenario = useCallback(
    (section) => {
      setSelectedScenario((prevState) => {
        // count how many true have it
        const trueCount = Object.values(prevState).filter(
          (value) => value === true,
        ).length;

        // if the current one is last true, can not change to false
        if (prevState[section] && trueCount === 1) {
          return prevState; // return the prevState
        }

        // if there is more than one true, could change this one to false
        return {
          ...prevState,
          [section]: !prevState[section],
        };
      });
    },
    [setSelectedScenario],
  );

  //the function used for judge whether need create a new page
  const addPageIfNeeded = (doc, yOffset) => {
    if (yOffset > 280) {
      doc.addPage();
      return 20; // Reset yOffset to top of new page
    }
    return yOffset;
  };

  // the PDF format of Basic Details in the concern flow stage
  const formatBasicDetails = (doc, data, yOffset) => {
    doc.setFontSize(16);
    doc.setFont("Segoe UI", "bold");

    if (
      Object.values(selectedSections["Basic Details"]).some(
        (value) => value === true,
      )
    ) {
      doc.text("Basic Details", 10, yOffset); // (text, x-aix position, y-axis position)
      yOffset += 10;
    }

    //function add the data belong to the Basic details to display in the PDF file
    const addField = (label, value) => {
      yOffset = addPageIfNeeded(doc, yOffset);
      doc.setFontSize(12);
      doc.setFont("Segoe UI", "bold");
      doc.text(label, 10, yOffset);
      doc.setFont("Segoe UI", "normal");
      const lines = doc.splitTextToSize(value, 180);
      lines.forEach((line) => {
        yOffset += 7;
        if (yOffset > 280) {
          doc.addPage();
          yOffset = 20;
        }
        doc.text(line, 20, yOffset);
      });
      yOffset += 10;
    };

    // add the data belong to Basic details into PDF
    if (selectedSections["Basic Details"]["Decision Requested"] === true) {
      addField(
        "Decision Requested:",
        data["Base Attributes"]["Decision Requested"],
      );
    }

    if (selectedSections["Basic Details"]["Root Cause"] === true) {
      addField("Root Cause:", data["Base Attributes"]["Root Cause"]);
    }

    if (selectedSections["Basic Details"]["Part Number"] === true) {
      addField("Part Number:", clickNode.id);
    }

    if (selectedSections["Basic Details"]["Issue Number"] === true) {
      addField("Issue Number:", data["Base Attributes"]["Issue Number"]);
    }

    if (selectedSections["Basic Details"]["Requestor Name"] === true) {
      addField("Requestor Name:", data["Base Attributes"]["Requestor Name"]);
    }

    if (selectedSections["Basic Details"]["Requestor ID"] === true) {
      addField("Requestor ID:", data["Base Attributes"]["Requestor ID"]);
    }

    return yOffset;
  };

  // the PDF format of Change Action Evaluation in the concern flow stage
  const formatChangeActionEvaluation = (doc, data, yOffset) => {
    yOffset = addPageIfNeeded(doc, yOffset);
    doc.setFontSize(16);
    doc.setFont("Segoe UI", "bold");

    // Check whether the Change Action Evaluation part need print
    if (
      Object.values(selectedSections["Change Action Evaluation"]).some(
        (value) => value === true,
      ) &&
      Object.values(selectedScenario).some((value) => value === true)
    ) {
      doc.text("Change Action Evaluation", 10, yOffset); // (text, x-aix position, y-axis position)
      yOffset += 10;
    }

    // function add the data belong to the Change Action Evaluation to display in the PDF file
    const addScenario = (scenario) => {
      yOffset = addPageIfNeeded(doc, yOffset);
      doc.setFontSize(14);
      doc.setFont("Segoe UI", "bold");
      doc.text(scenario, 10, yOffset);
      yOffset += 10;

      const addField = (label, value) => {
        yOffset = addPageIfNeeded(doc, yOffset);
        doc.setFontSize(12);
        doc.setFont("Segoe UI", "bold");
        doc.text(label, 15, yOffset);
        doc.setFont("Segoe UI", "normal");
        const lines = doc.splitTextToSize(value, 175);
        lines.forEach((line) => {
          yOffset += 7;
          yOffset = addPageIfNeeded(doc, yOffset);
          doc.text(line, 25, yOffset);
        });
        yOffset += 8;
      };

      // use the function to add the data belong to Change Action Evaluation into PDF file
      if (
        selectedSections["Change Action Evaluation"]["Action Description"] ===
        true
      ) {
        addField(
          "Action Description:",
          data["Scenarios"][scenario]["Action Description"],
        );
      }

      if (selectedSections["Change Action Evaluation"]["Benefits"] === true) {
        addField("Benefits:", data["Scenarios"][scenario]["Benefits"]);
      }

      if (
        selectedSections["Change Action Evaluation"][
          "Cost-Mass-Time-Quality Effect"
        ] === true
      ) {
        addField(
          "Cost Effect:",
          data["Scenarios"][scenario]["Cost Effect"] || "$5 per Vehicle",
        );
        addField(
          "Mass Effect:",
          data["Scenarios"][scenario]["Mass/ Weight Effect"] ||
            "0.15 kg Increase",
        );
        addField(
          "Manufacturing Effect:",
          data["Scenarios"][scenario][
            "Effect on Vehicle Operations (VO) / Manufacturing"
          ] || "None",
        );
        addField(
          "Timing Effect:",
          data["Scenarios"][scenario]["Timing effect"] || "Yes",
        );
        addField(
          "Quality Effect:",
          data["Scenarios"][scenario]["Quality Effect"] || "None Effect",
        );
        addField(
          "Time Effect Explain:",
          data["Scenarios"][scenario]["Time effect explain"] ||
            "Tooling modification to Axle housing tool",
        );
      }

      if (
        selectedSections["Change Action Evaluation"]["Activities Impacted"] ===
        true
      ) {
        addField(
          "Activities Impacted:",
          data["Scenarios"][scenario]["Activities Impacted"] || "Safety",
        );
      }

      yOffset += 10;
    };

    // judge which Scenario data show add in the pdf file ==> based on the user selection
    if (
      selectedScenario["Scenario 1"] === true &&
      Object.values(selectedSections["Change Action Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 1");
    }
    if (
      selectedScenario["Scenario 2"] === true &&
      Object.values(selectedSections["Change Action Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 2");
    }
    if (
      selectedScenario["Scenario 3"] === true &&
      Object.values(selectedSections["Change Action Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 3");
    }

    return yOffset;
  };

  // the PDF format of Business Case Evaluation in the concern flow stage
  const formatBusinessCaseEvaluation = (doc, data, yOffset) => {
    yOffset = addPageIfNeeded(doc, yOffset);
    doc.setFontSize(16);
    doc.setFont("Segoe UI", "bold");

    // Check whether the Change Action Evaluation part need print
    if (
      Object.values(selectedSections["Business Case Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      doc.text("Business Case Evaluation", 10, yOffset); // (text, x-aix position, y-axis position)
      yOffset += 10;
    }

    // the function to add the data belong to Business Case Evaluation into PDF file
    const addField = (label, value) => {
      yOffset = addPageIfNeeded(doc, yOffset);
      doc.setFontSize(12);
      doc.setFont("Segoe UI", "bold");
      doc.text(label, 15, yOffset);
      doc.setFont("Segoe UI", "normal");
      doc.text(value, 100, yOffset);
      yOffset += 7;
    };

    // Add the data belong to Business Case Evaluation into PDF file
    if (
      selectedSections["Business Case Evaluation"]["Basic Details"] === true
    ) {
      addField(
        "Volume of Vehicle/year:",
        data["Scenarios"]["Scenario 1"]["Total volume of vehicle per year"] ||
          "800,000",
      );
      addField(
        "Affected Vehicles:",
        data["Scenarios"]["Scenario 1"]["Affected Vehicles"] || "8000",
      );
      addField(
        "Mix Rate (%):",
        data["Scenarios"]["Scenario 1"]["Mix Rate (%)"] || "100%",
      );
      addField(
        "Fixing Cost/vehicle ($):",
        data["Scenarios"]["Scenario 1"]["Fixing Cost/vehicle"] || "5.00",
      );
      yOffset += 10;
    }

    yOffset = addPageIfNeeded(doc, yOffset);
    doc.setFontSize(14);
    doc.setFont("Segoe UI", "bold");

    // if the user selected the Scenario 1 2 3 and the Business Case Comparisons item
    if (
      selectedSections["Business Case Evaluation"][
        "Business Case Comparisons"
      ] === true &&
      Object.values(selectedScenario).some((value) => value === true)
    ) {
      doc.text("Business Case Comparisons", 15, yOffset);
      yOffset += 10;
    }

    // function to add the Scenario data belong to Business case into PDF file display
    const addScenario = (scenario) => {
      yOffset = addPageIfNeeded(doc, yOffset);
      doc.setFontSize(13);
      doc.setFont("Segoe UI", "bold");
      doc.text(scenario, 15, yOffset);
      yOffset += 7;

      if (
        selectedSections["Business Case Evaluation"][
          "Business Case Comparisons"
        ] === true
      ) {
        addField(
          "Total Cost ($):",
          data["Scenarios"][scenario][
            "Total Cost at Dealership - (Company Cost + Investment)"
          ] || "859,000",
        );
        addField(
          "Contribution Costs ($):",
          data["Scenarios"][scenario]["Contribution Costs"] || "616,000",
        );
        addField(
          "Delta cost ($):",
          data["Scenarios"][scenario]["Delta"] || "0.77",
        );
        addField(
          "Total Investment ($):",
          data["Scenarios"][scenario]["Total Investment"] || "25,000",
        );
        addField(
          "Tooling ($):",
          data["Scenarios"][scenario]["Tooling"] || "25,000",
        );
        addField(
          "L-S ED&D ($):",
          data["Scenarios"][scenario]["L-S ED&D"] || "0",
        );
        addField(
          "Additional Weight:",
          data["Scenarios"][scenario]["Additional weight"] || "None",
        );
        addField(
          "Testing/Prototype ($):",
          data["Scenarios"][scenario]["Testing / Prototype"] || "15000",
        );
        addField("Avg. Revenue ($):", data["Scenarios"][scenario]["Avg. Rev."]);
        addField(
          "Profit Margin ($):",
          data["Scenarios"][scenario]["Profit Margin"],
        );
        addField("TARR:", data["Scenarios"][scenario]["TARR"]);
        addField(
          "Anticipated Warranty Costs ($):",
          data["Scenarios"][scenario]["Anticipated Warranty Costs ($)"] ||
            "3500",
        );
        addField(
          "Time to fix:",
          data["Scenarios"][scenario]["Time taken to fix"] || "3.5 hrs",
        );
        addField(
          "Warranty Rate:",
          data["Scenarios"][scenario]["Warranty Rate"] || "$125/hr",
        );
        addField(
          "Cost to fix/vehicle ($):",
          data["Scenarios"][scenario]["Cost to fix/vehicle"] || "437.5",
        );
        yOffset += 5;
      }
    };

    // judge which Scenario data show add in the pdf file ==> based on the user selection Scenario 1 2 3
    if (
      selectedScenario["Scenario 1"] === true &&
      Object.values(selectedSections["Business Case Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 1");
    }
    if (
      selectedScenario["Scenario 2"] === true &&
      Object.values(selectedSections["Business Case Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 2");
    }
    if (
      selectedScenario["Scenario 3"] === true &&
      Object.values(selectedSections["Business Case Evaluation"]).some(
        (value) => value === true,
      )
    ) {
      addScenario("Scenario 3");
    }

    return yOffset;
  };

  // the print function ==> print the user selected of concern flow stage
  const handlePrint = () => {
    const doc = new jsPDF();
    let yOffset = 15;

    // need setup the main tittle in the center position
    const pageWidth = doc.internal.pageSize.getWidth(); // the width of pdf page
    const text = `The Concern Flow Stage Data Details - ${clickNode["Part name"]}`; // the main tittle text
    const textWidth = doc.getTextWidth(text); // the text length of the main tittle

    doc.setFontSize(18);
    doc.setFont("Segoe UI");
    doc.text(text, (pageWidth - textWidth) / 2, yOffset); // center position
    yOffset += 15;

    // function to add user cilciked the component name below of main tittle
    const addField = (label, value) => {
      yOffset = addPageIfNeeded(doc, yOffset);
      doc.setFontSize(12);
      doc.setFont("Segoe UI", "bold"); //(text, x-position, y-position)
      doc.text(label, 10, yOffset);
      doc.setFont("Segoe UI", "normal");
      doc.text(value, 70, yOffset); // (text, x-position, y-position)
      yOffset += 10;
    };

    addField("Selected Component:", clickNode["Part name"]);

    // judge which part need to be print: Basic Details, Change Action Evaluation and Business Case Evaluation, Realtive Notice
    if (selectedSections["Basic Details"]) {
      yOffset = formatBasicDetails(doc, concernData, yOffset) + 10;
    }
    if (selectedSections["Change Action Evaluation"]) {
      if (yOffset > 240) {
        doc.addPage();
        yOffset = 20;
      }
      yOffset = formatChangeActionEvaluation(doc, concernData, yOffset) + 10;
    }
    if (selectedSections["Business Case Evaluation"]) {
      if (yOffset > 240) {
        doc.addPage();
        yOffset = 20;
      }
      yOffset = formatBusinessCaseEvaluation(doc, concernData, yOffset);
    }

    doc.save(`Component Flow Stage Details - ${clickNode["Part name"]}.pdf`);
    onDismiss();
  };

  return (
    <div className="printModal" onMouseMove={onDrag} onMouseUp={endDrag}>
      <div
        className="printModal-content"
        onMouseDown={startDrag}
        style={{ top: `${position.y}px`, left: `${position.x}px` }}
      >
        <h2>Select & Print</h2>
        <DismissFilled onClick={onDismiss} className="cancelButton" />
        <div className="printSelectionTittle">
          <PrintSelection style={{ width: "15px", height: "15px" }} />
          <label>Scenarios</label>
        </div>
        <Checkbox
          label="Scenario 1"
          checked={selectedScenario["Scenario 1"]}
          className="printSelectionItem"
          styles={{
            root: checkboxStyles.root,
            checkbox: {
              width: "14px",
              height: "14px",
              margin: "8px",
              borderColor: "#616161",
            },
            checkmark: {
              background: "#066755",
              color: "white",
              fontWeight: "bold",
            },
            label: {
              display: "flex",
              alignItems: "center",
            },
          }}
          onChange={() => handleSelectedScenario("Scenario 1")}
        />

        <Checkbox
          label="Scenario 2"
          checked={selectedScenario["Scenario 2"]}
          className="printSelectionItem"
          styles={{
            checkbox: {
              width: "14px",
              height: "14px",
              borderColor: "#616161",
              margin: "8px",
            },
            checkmark: {
              background: "#066755",
              color: "white",
              fontWeight: "bold",
            },
            label: {
              display: "flex",
              alignItems: "center",
            },
          }}
          onChange={() => handleSelectedScenario("Scenario 2")}
        />

        <Checkbox
          label="Scenario 3"
          checked={selectedScenario["Scenario 3"]}
          className="printSelectionItem"
          styles={{
            root: checkboxStyles.root,
            checkbox: {
              width: "14px",
              height: "14px",
              borderColor: "#616161",
              margin: "8px",
            },
            checkmark: {
              background: "#066755",
              color: "white",
              fontWeight: "bold",
            },
            label: {
              display: "flex",
              alignItems: "center",
            },
          }}
          onChange={() => handleSelectedScenario("Scenario 3")}
        />

        <div className="printSelectionTittle">
          <PrintSelection
            style={{ width: "15px", height: "15px", color: "#066755" }}
          />
          <label style={{ marginLeft: "26px" }}>Concern Flow Stage</label>
        </div>

        <AccordionSelectionItem
          title="Basic Details"
          isOpen={openSection["Basic Details"]}
          isChecked={isAllSelected("Basic Details")} // based on the children selection judge is true or false
          onCheckboxChange={() => handleParentCheckboxChange("Basic Details")} // used for follow the parents chcekbox change
          onClick={() => toggleSection("Basic Details")}
        >
          {Object.keys(selectedSections["Basic Details"]).map((subSection) => (
            <Checkbox
              key={subSection}
              label={subSection}
              checked={selectedSections["Basic Details"][subSection]}
              onChange={() => handleCheckboxChange("Basic Details", subSection)}
              styles={{
                root: checkboxStyles.root,
                checkbox: {
                  width: "14px",
                  height: "14px",
                  borderColor: "#616161",
                },
                checkmark: {
                  background: "#066755",
                  color: "white",
                  fontWeight: "bold",
                },
                label: {
                  display: "flex",
                  alignItems: "center",
                },
                text: {
                  fontSize: "13px !important",
                },
              }}
            />
          ))}
        </AccordionSelectionItem>

        <AccordionSelectionItem
          title="Change Action Evaluation"
          isOpen={openSection["Change Action Evaluation"]}
          isChecked={isAllSelected("Change Action Evaluation")} // based on the children selection judge is true or false
          onCheckboxChange={() =>
            handleParentCheckboxChange("Change Action Evaluation")
          } // used for follow the parents chcekbox change
          onClick={() => toggleSection("Change Action Evaluation")}
        >
          {Object.keys(selectedSections["Change Action Evaluation"]).map(
            (subSection) => (
              <Checkbox
                key={subSection}
                label={subSection}
                checked={
                  selectedSections["Change Action Evaluation"][subSection]
                }
                onChange={() =>
                  handleCheckboxChange("Change Action Evaluation", subSection)
                }
                styles={{
                  root: checkboxStyles.root,
                  checkbox: {
                    width: "14px",
                    height: "14px",
                    borderColor: "#616161",
                  },
                  checkmark: {
                    background: "#066755",
                    color: "white",
                    fontWeight: "bold",
                  },
                  label: {
                    display: "flex",
                    alignItems: "center",
                  },
                  text: {
                    fontSize: "13px !important",
                  },
                }}
              />
            ),
          )}
        </AccordionSelectionItem>

        <AccordionSelectionItem
          title="Business Case Evaluation"
          isOpen={openSection["Business Case Evaluation"]}
          isChecked={isAllSelected("Business Case Evaluation")} // based on the children selection judge is true or false
          onCheckboxChange={() =>
            handleParentCheckboxChange("Business Case Evaluation")
          } // used for follow the parents chcekbox change
          onClick={() => toggleSection("Business Case Evaluation")}
        >
          {Object.keys(selectedSections["Business Case Evaluation"]).map(
            (subSection) => (
              <Checkbox
                key={subSection}
                label={subSection}
                checked={
                  selectedSections["Business Case Evaluation"][subSection]
                }
                onChange={() =>
                  handleCheckboxChange("Business Case Evaluation", subSection)
                }
                styles={{
                  root: checkboxStyles.root,
                  checkbox: {
                    width: "14px",
                    height: "14px",
                    borderColor: "#616161",
                  },
                  checkmark: {
                    background: "#066755",
                    color: "white",
                    fontWeight: "bold",
                  },
                  label: {
                    display: "flex",
                    alignItems: "center",
                  },
                  text: {
                    fontSize: "13px !important",
                  },
                }}
              />
            ),
          )}
        </AccordionSelectionItem>

        <AccordionSelectionItem
          title="Release Notice"
          isOpen={openSection["Release Notice"]}
          isChecked={isAllSelected("Release Notice")} // based on the children selection judge is true or false
          onCheckboxChange={() => handleParentCheckboxChange("Release Notice")} // used for follow the parents chcekbox change
          onClick={() => toggleSection("Release Notice")}
        >
          <Checkbox
            label="Release Notice"
            checked={selectedSections["Release Notice"]}
            onChange={() => handleCheckboxChange("Release Notice")}
            styles={{
              root: checkboxStyles.root,
              checkbox: {
                width: "14px",
                height: "14px",
                borderColor: "#616161",
              },
              checkmark: {
                background: "#066755",
                color: "white",
                fontWeight: "bold",
              },
              label: {
                display: "flex",
                alignItems: "center",
              },
              text: {
                fontSize: "13px !important",
              },
            }}
          />
        </AccordionSelectionItem>

        <div className="button-container ">
          <button onClick={handlePrint} className="print-button">
            Print
          </button>
          <button onClick={onDismiss} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintModal;
