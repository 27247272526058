/*  the analytics bar char in the right side Properties area:
   1. Mass MeterChart:  currNode["Actual Mass (kg)"] / currNode["Target Mass (kg)"] （kg)  
   2. Investment MeterChart: currNode["Investment"] / currNode["Target Investment"] ($)  
   3. Cost MeterChart: currNode["Total piece cost"] / currNode["Target piece cost"] ($)  
   4. Time MeterChart: currNode["Total part development time"] / currNode["Time Allocated"] weeks 

   110%+ ==> #ECB8BB color ==> dangerous
   100% - 110% ==> #D6C9A1 color ==> warning
   0 - 100% ==> #A1BDBD color ==> success 
*/

import React from "react";
import { ProgressIndicator } from "@carbon/react";
import {
  CheckmarkFilled,
  WarningFilled,
  ErrorFilled,
} from "@carbon/icons-react";

const BarChart = ({ currNode }) => {
  //const the pencatage of each: mass, cost, investment, time
  const calculatePercentage = (actual, target) => {
    if (actual === 0 && target === 0) {
      return 0;
    }

    return (actual / target) * 100;
  };

  // function to caculate the bar chart color
  const getColor = (value) => {
    if (value === 0) {
      return "var(--Layer-layer-01, #F4F4F4)";
    }
    if (value > 110) return "var(--Categorical---Full-Red-50, #DA1E28)";
    if (value > 100 && value <= 110)
      return "var(--Categorical---Full-Yellow-50, #F1C21B)";
    return "#24A148";
  };

  const getBorderColor = (value) => {
    if (value === 0) {
      return "1px solid #F4F4F4";
    }
    if (value > 110) return "1px solid #9F1853";
    if (value > 100 && value <= 110) return "1px solid #B28600";
    return "1px solid #198038";
  };

  // build the state icon with color
  const getIcon = (value) => {
    if (value === 0) {
      return <WarningFilled className="status-icon" fill="#D6C9A1" />;
    }
    if (value > 110)
      return <ErrorFilled className="status-icon" fill="#DA1E28" />;
    if (value > 100)
      return <WarningFilled className="status-icon" fill="#F1C21B" />;
    return <CheckmarkFilled className="status-icon" fill="#24A148" />;
  };

  // the cost, mass, investment, time data of current user click node
  const fourData = [
    {
      group: "Mass",
      actual: currNode["Mass (gms)"] || 0,
      target: currNode["Target Mass (gms)"] || 0,
      unit: "gms",
    },
    {
      group: "Investment",
      actual: currNode["Actual Investment"] || 0,
      target: currNode["Target Investment"] || 0,
      unit: "$",
    },
    {
      group: "Cost",
      actual: currNode["Total piece cost ($)"] || 0,
      target: currNode["Target piece cost ($)"] || 0,
      unit: "$",
    },
    {
      group: "Time",
      actual: currNode["Total part development time"] || 0,
      target: currNode["Time Allocated"] || 0,
      unit: "weeks",
    },
  ];

  //function to add the units into data
  const formatValue = (value, unit) => {
    if (unit === "$") {
      return `$${value}`;
    }
    return `${value} ${unit}`;
  };

  // the total render function ==> layout: icon, name, percentage, actual / target
  const renderChartItem = (item) => {
    const percentage = calculatePercentage(item.actual, item.target);
    const color = getColor(percentage);
    const icon = getIcon(percentage);
    const borderColor = getBorderColor(percentage);

    return (
      <div
        key={item.group}
        className="chart-item"
        style={{ width: "calc(50% - 8px)" }}
      >
        {/* the title part: icon, chart name, percentage, and actul value / target value */}
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          {icon}
          <span
            style={{
              marginLeft: "8px",
              flex: 1,
              color: "var(--Text-text-primary, #161616)",
              fontFamily: "Inter",
              fontSize: "12px",
            }}
          >
            {item.group} {percentage.toFixed(1)}%
          </span>
          <div style={{ textAlign: "right" }}>
            {item.group !== "Time" ? (
              <p
                style={{
                  color: "var(--Text-text-primary, #161616)",
                  fontFamily: "Inter",
                  fontSize: "11px",
                }}
              >
                {formatValue(item.actual.toFixed(2), item.unit)} of{" "}
                {formatValue(item.target.toFixed(2), item.unit)}
              </p>
            ) : (
              <p
                style={{
                  color: "var(--Text-text-primary, #161616)",
                  fontFamily: "Inter",
                  fontSize: "11px",
                }}
              >
                {formatValue(item.actual, item.unit)} of{" "}
                {formatValue(item.target, item.unit)}
              </p>
            )}
          </div>
        </div>

        <div
          style={{
            position: "relative",
            height: "10px",
            backgroundColor: "var(--Layer-layer-01, #F4F4F4)",
            borderRight: "1px solid #ccc",
          }}
        >
          <ProgressIndicator
            value={Math.min(percentage, 170)}
            max={170} // Set max to 170% to allow overflowing
            style={{
              position: "absolute",
              width: "90%",
              height: "calc(100% + 2px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "59%", // 100% position (100/180 * 100)
              top: "50%",
              transform: "translateY(-50%)",
              width: "2px",
              height: "100%",
              backgroundColor: "black",
              zIndex: 2,
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              width: `calc(${Math.min((percentage / 170) * 100, 100)}% - 2px)`,
              height: "calc(100% - 2px)",
              backgroundColor: color,
              border: borderColor,
              transition: "width 0.5s ease",
              zIndex: 1,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="barChart">
      <div className="chartContainer">
        {renderChartItem(fourData[0])}
        {renderChartItem(fourData[1])}
      </div>
      <div className="chartContainer">
        {renderChartItem(fourData[2])}
        {renderChartItem(fourData[3])}
      </div>
    </div>
  );
};

export default BarChart;
