import Discover from "../Pages/Module Discover/Discover";
import Achieve from "../Pages/Module Achieve/Achieve";
import HelpCenter from "../Pages/Help Center/HelpCenter";
import Login from "../Pages/Global Component/LogIn_SignUp/Login";
import UserPage from "../Pages/Module UserProfile/UserPage";
import Setup from "../Pages/Setup Area/Setup";
import Drive from "../Pages/Module Drive/Drive";
import TeamPage from "../Pages/Global Component/Team/TeamPage";
import SharedView from "../Pages/Shared/SharedView";
import { Navigate } from "react-router-dom";

export const routes = [
  {
    path: "/",
    element: <Navigate to="/discover" replace />,
    protected: false,
  },
  {
    path: "/login",
    element: <Login />,
    protected: false,
  },
  {
    path: "/drive",
    element: <Drive />,
    protected: true,
  },
  {
    path: "/achieve",
    element: <Achieve />,
    protected: true,
  },
  {
    path: "/discover",
    element: <Discover />,
    protected: true,
  },
  {
    path: "/help-center",
    element: <HelpCenter />,
    protected: true,
  },
  {
    path: "/setup",
    element: <Setup />,
    protected: true,
  },
  {
    path: "/user-account",
    element: <UserPage />,
    protected: true,
  },
  {
    path: "/team",
    element: <TeamPage />,
    protected: true,
  },
  {
    path: "/shared",
    element: <SharedView />,
    protected: false,
  },
];
