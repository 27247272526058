import React from "react";
import PropTypes from "prop-types";

const PdfIcon = ({
  size = 24,
  color = "#D0D5DD",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={(size * 24) / 28}
    viewBox="0 0 28 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6 23.1H25C25.8284 23.1 26.5 22.4284 26.5 21.6V7.27279L20.1272 0.9H10.6C9.77152 0.9 9.09995 1.57157 9.09995 2.4V21.6C9.09995 22.4284 9.77152 23.1 10.6 23.1ZM10.6 0C9.27447 0 8.19995 1.07452 8.19995 2.4V21.6C8.19995 22.9255 9.27447 24 10.6 24H25C26.3254 24 27.4 22.9255 27.4 21.6V7.2C27.4 7.00791 27.3236 6.82369 27.1878 6.68787L20.7121 0.212132C20.5763 0.0763062 20.392 0 20.2 0H10.6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 4.7998V0.299805H20.65V4.7998C20.65 5.87676 21.523 6.7498 22.6 6.7498H27.1V7.6498H22.6C21.026 7.6498 19.75 6.37382 19.75 4.7998Z"
      fill={color}
    />
    <rect
      x="0.117432"
      y="9.96387"
      width="24.5653"
      height="11.2727"
      rx="2"
      fill="#D92D20"
    />
    <path
      d="M16.8674 19.2366V11.9639H21.6828V13.2316H18.4051V14.9646H21.3632V16.2323H18.4051V19.2366H16.8674Z"
      fill="white"
    />
    <path
      d="M12.1702 19.2366H9.59204V11.9639H12.1915C12.923 11.9639 13.5527 12.1095 14.0807 12.4007C14.6086 12.6895 15.0146 13.105 15.2987 13.6471C15.5852 14.1892 15.7284 14.8379 15.7284 15.5931C15.7284 16.3507 15.5852 17.0017 15.2987 17.5463C15.0146 18.0908 14.6062 18.5086 14.0736 18.7998C13.5433 19.091 12.9088 19.2366 12.1702 19.2366ZM11.1297 17.9191H12.1062C12.5608 17.9191 12.9431 17.8386 13.2533 17.6776C13.5658 17.5143 13.8001 17.2622 13.9564 16.9213C14.115 16.578 14.1943 16.1353 14.1943 15.5931C14.1943 15.0557 14.115 14.6166 13.9564 14.2757C13.8001 13.9347 13.5669 13.6838 13.2568 13.5228C12.9467 13.3618 12.5643 13.2813 12.1098 13.2813H11.1297V17.9191Z"
      fill="white"
    />
    <path
      d="M3.11743 19.2366V11.9639H5.98675C6.53836 11.9639 7.00829 12.0692 7.39655 12.2799C7.78481 12.4883 8.08074 12.7783 8.28434 13.1499C8.4903 13.5193 8.59328 13.9454 8.59328 14.4284C8.59328 14.9113 8.48912 15.3374 8.28078 15.7068C8.07245 16.0761 7.7706 16.3637 7.37524 16.5697C6.98225 16.7757 6.5064 16.8786 5.94769 16.8786H4.11885V15.6464H5.69911C5.99504 15.6464 6.23888 15.5955 6.43064 15.4937C6.62477 15.3895 6.76918 15.2463 6.86388 15.064C6.96095 14.8794 7.00948 14.6675 7.00948 14.4284C7.00948 14.1869 6.96095 13.9762 6.86388 13.7963C6.76918 13.614 6.62477 13.4731 6.43064 13.3737C6.23651 13.2719 5.9903 13.221 5.69201 13.221H4.65507V19.2366H3.11743Z"
      fill="white"
    />
  </svg>
);

PdfIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default PdfIcon;