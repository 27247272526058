// Re usebale library: the input area ==> search bar, form, the login and sign in
// Reuseable component: the input feils: general and error status
import React from "react";
import PropTypes from "prop-types";
import "./InputFields.css";
import AlertCircle from "../../icons/Alerts/AlertCircle";

// Function to format phone number in (XXX) XXX-XXXX format
const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber; // Return unformatted if the input doesn't match the expected length
};

// used type to change layout
const InputFields = ({
  label,
  name,
  value,
  placeholder,
  isRequired,
  hint,
  errorMessage,
  type,
  hasIcon,
  width,
  height,
  onChange,
  isPassword,
  isPhoneNumber = false,
}) => {
  const displayedValue = isPhoneNumber ? formatPhoneNumber(value) : value;

  return (
    <div className={`input-container ${type}`}>
      <label className="input-label">
        {label}{" "}
        {isRequired && (
          <span
            style={{
              color:
                type === "errorWithGray" ||
                type === "errorWithBlack" ||
                type === "activeErrorFocus"
                  ? "var(--danger-600)"
                  : "var(--brand-secondary-400",
            }}
          >
            *
          </span>
        )}
      </label>
      <div className={`input-field`} style={{ width, height }}>
        <input
          type={isPassword ? "password" : "text"} // Set the input type based on isPassword prop
          placeholder={placeholder}
          name={name}
          value={displayedValue} // Bind the value here
          onChange={onChange} // Handle input changes
          disabled={type === "disabled"}
        />
        {hasIcon && (
          <span className="input-icon">
            <AlertCircle color="var(--danger-500)" />
          </span>
        )}
      </div>
      <p className={`input-hint`}>{errorMessage ? errorMessage : hint}</p>
    </div>
  );
};

InputFields.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  hint: PropTypes.string,
  errorMessage: PropTypes.string,
  hasIcon: PropTypes.bool,
  type: PropTypes.oneOf([
    "generalWithGray",
    "generalWithBlack",
    "activeFocus",
    "disabled",
    "errorWithGray",
    "errorWithBlack",
    "activeErrorFocus",
  ]),
  isPassword: PropTypes.bool, // New prop for password input
  isPhoneNumber: PropTypes.bool,
};

export default InputFields;
