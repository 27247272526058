import React from "react";
import { useLocation } from 'react-router-dom';
import IssueArea from "../Module Discover/Issue Table/IssueArea";
import { useQuery } from 'react-query';
import api from '../../api/config';
import { useAppContext } from "../../AppContext";
import { mapIssueFields } from '../../config/issueMapping';

const fetchIssuesByIds = async (issueIds) => {
  const response = await api.get(`/search/issues?issuelist=${issueIds}`, {
    headers: {
      'Accept': 'application/json',
    }
  });
  
  return response.data.data.map(issue => mapIssueFields(issue));
};

const SharedView = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const issueList = searchParams.get('issuelist');
  const { setIssueDisplayArray } = useAppContext();

  const { data: issues = [], isLoading, error } = useQuery(
    ['sharedIssues', issueList],
    () => fetchIssuesByIds(issueList),
    {
      enabled: !!issueList,
      onSuccess: (data) => {
        setIssueDisplayArray(data);
      }
    }
  );

  if (error) return <div>Error loading shared issues</div>;
  if (!issueList) return <div>No issues to display</div>;
  
  if (isLoading) {
    return (
      <div className="fade-in">
        <div className="dots-loader">
          {Array.from({ length: 16 }).map((_, index) => (
            <div
              key={index}
              className="dot"
              style={{
                transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                animationDelay: `${index * 0.125}s`
              }}
            />
          ))}
        </div>
        <p style={{ marginLeft: "8px" }}>
          Loading shared issues...
        </p>
      </div>
    );
  }

  return (
    <div className="discover">
      <IssueArea isSharedView={true} />
    </div>
  );
};

export default SharedView; 