//module 1 (Drive) page: Evaluates different cost implications for issue resolution
import "./drive.css";
import React, { useEffect, useRef } from "react";
import nodeInfor from "../../datasets/data_cleaning/Drive Module Data/drive_v1.json";
import NodeGraph from "./Node Graph Area/NodeGraph";
import InforArea from "./Information Data Area/InforArea";
import { useAppContext } from "../../AppContext";
import DriveComeSoon from "../ComeSoon/DriveComeSoon";

const Drive = () => {
  //used for to print the 2D chart and 3D node graph into a PDF file
  const forceGraphRef = useRef();

  const { setData } = useAppContext();

  useEffect(() => {
    setData(nodeInfor);
  }, [setData]);

  return (
    // <div style={{ display: "flex", flexWrap: "wrap" }}>
    //   <NodeGraph forceGraphRef={forceGraphRef} />

    //   <InforArea />
    // </div>
    <div>
      <DriveComeSoon/>
    </div>
  );
};

export default Drive;
