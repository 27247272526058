import React from "react";
import PropTypes from "prop-types";

const DocxIcon = ({
  size = 28,
  color = "#D0D5DD",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={(size * 24) / 28}
    viewBox="0 0 35 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6 23.1H27C27.8284 23.1 28.5 22.4284 28.5 21.6V7.27279L22.1272 0.9H12.6C11.7715 0.9 11.1 1.57157 11.1 2.4V21.6C11.1 22.4284 11.7715 23.1 12.6 23.1ZM12.6 0C11.2745 0 10.2 1.07452 10.2 2.4V21.6C10.2 22.9255 11.2745 24 12.6 24H27C28.3254 24 29.4 22.9255 29.4 21.6V7.2C29.4 7.00791 29.3236 6.82369 29.1878 6.68787L22.7121 0.212132C22.5763 0.0763062 22.392 0 22.2 0H12.6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 4.7998V0.299805H22.65V4.7998C22.65 5.87676 23.523 6.7498 24.6 6.7498H29.1V7.6498H24.6C23.026 7.6498 21.75 6.37382 21.75 4.7998Z"
      fill={color}
    />
    <rect
      x="0.0568848"
      y="9.86426"
      width="34.6861"
      height="11.4716"
      rx="2"
      fill="#155EEF"
    />
    <path
      d="M26.9596 11.9639L28.4262 14.4426H28.483L29.9567 11.9639H31.6932L29.4738 15.6002L31.7429 19.2366H29.9745L28.483 16.7544H28.4262L26.9347 19.2366H25.1733L27.4496 15.6002L25.216 11.9639H26.9596Z"
      fill="white"
    />
    <path
      d="M24.5714 14.5099H23.016C22.9875 14.3086 22.9295 14.1299 22.842 13.9736C22.7544 13.815 22.6419 13.6801 22.5046 13.5688C22.3673 13.4575 22.2087 13.3723 22.0287 13.3131C21.8512 13.2539 21.6582 13.2243 21.4499 13.2243C21.0735 13.2243 20.7456 13.3179 20.4662 13.5049C20.1869 13.6895 19.9703 13.9594 19.8164 14.3145C19.6625 14.6673 19.5856 15.0958 19.5856 15.6001C19.5856 16.1185 19.6625 16.5541 19.8164 16.9069C19.9726 17.2596 20.1904 17.526 20.4698 17.7059C20.7491 17.8858 21.0723 17.9758 21.4393 17.9758C21.6452 17.9758 21.8358 17.9485 22.011 17.8941C22.1885 17.8396 22.346 17.7603 22.4833 17.6562C22.6206 17.5496 22.7342 17.4206 22.8242 17.2691C22.9165 17.1176 22.9804 16.9448 23.016 16.7506L24.5714 16.7577C24.5311 17.0915 24.4305 17.4135 24.2695 17.7236C24.1109 18.0314 23.8966 18.3072 23.6268 18.551C23.3592 18.7925 23.0396 18.9843 22.6679 19.1263C22.2986 19.266 21.8808 19.3358 21.4144 19.3358C20.7657 19.3358 20.1857 19.1891 19.6743 18.8955C19.1653 18.6019 18.7629 18.177 18.467 17.6206C18.1734 17.0643 18.0266 16.3908 18.0266 15.6001C18.0266 14.807 18.1758 14.1323 18.4741 13.5759C18.7723 13.0196 19.1772 12.5958 19.6885 12.3046C20.1999 12.011 20.7752 11.8643 21.4144 11.8643C21.8358 11.8643 22.2264 11.9234 22.5863 12.0418C22.9485 12.1602 23.2693 12.333 23.5486 12.5603C23.828 12.7852 24.0553 13.061 24.2304 13.3877C24.408 13.7144 24.5216 14.0885 24.5714 14.5099Z"
      fill="white"
    />
    <path
      d="M17.0154 15.6001C17.0154 16.3931 16.8651 17.0679 16.5644 17.6242C16.2661 18.1805 15.8589 18.6055 15.3428 18.8991C14.8291 19.1903 14.2515 19.3358 13.6099 19.3358C12.9636 19.3358 12.3836 19.1891 11.8698 18.8955C11.3561 18.6019 10.9501 18.177 10.6518 17.6206C10.3535 17.0643 10.2043 16.3908 10.2043 15.6001C10.2043 14.807 10.3535 14.1323 10.6518 13.5759C10.9501 13.0196 11.3561 12.5958 11.8698 12.3046C12.3836 12.011 12.9636 11.8643 13.6099 11.8643C14.2515 11.8643 14.8291 12.011 15.3428 12.3046C15.8589 12.5958 16.2661 13.0196 16.5644 13.5759C16.8651 14.1323 17.0154 14.807 17.0154 15.6001ZM15.4565 15.6001C15.4565 15.0863 15.3795 14.6531 15.2257 14.3003C15.0741 13.9476 14.8599 13.6801 14.5829 13.4978C14.3059 13.3155 13.9816 13.2243 13.6099 13.2243C13.2382 13.2243 12.9139 13.3155 12.6369 13.4978C12.3599 13.6801 12.1444 13.9476 11.9906 14.3003C11.8391 14.6531 11.7633 15.0863 11.7633 15.6001C11.7633 16.1138 11.8391 16.547 11.9906 16.8998C12.1444 17.2525 12.3599 17.52 12.6369 17.7023C12.9139 17.8846 13.2382 17.9758 13.6099 17.9758C13.9816 17.9758 14.3059 17.8846 14.5829 17.7023C14.8599 17.52 15.0741 17.2525 15.2257 16.8998C15.3795 16.547 15.4565 16.1138 15.4565 15.6001Z"
      fill="white"
    />
    <path
      d="M5.63501 19.2366H3.05688V11.9639H5.65632C6.38785 11.9639 7.01759 12.1095 7.54552 12.4007C8.07346 12.6895 8.47947 13.105 8.76356 13.6471C9.05002 14.1892 9.19325 14.8379 9.19325 15.5931C9.19325 16.3507 9.05002 17.0017 8.76356 17.5463C8.47947 18.0908 8.07109 18.5086 7.53842 18.7998C7.00812 19.091 6.37365 19.2366 5.63501 19.2366ZM4.59453 17.9191H5.57109C6.02563 17.9191 6.40797 17.8386 6.71811 17.6776C7.03061 17.5143 7.26498 17.2622 7.42123 16.9213C7.57985 16.578 7.65916 16.1353 7.65916 15.5931C7.65916 15.0557 7.57985 14.6166 7.42123 14.2757C7.26498 13.9347 7.03179 13.6838 6.72166 13.5228C6.41153 13.3618 6.02919 13.2813 5.57464 13.2813H4.59453V17.9191Z"
      fill="white"
    />
  </svg>
);

DocxIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default DocxIcon;
