import React from "react";
import PropTypes from "prop-types";
const SvgUser = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7M6.5 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0m2.826 7h5.348c1.227 0 2.065 0 2.777.215a5 5 0 0 1 3.334 3.334c.216.712.215 1.55.215 2.777V21a1 1 0 0 1-2 0c0-1.468-.01-1.98-.13-2.37a3 3 0 0 0-2-2c-.39-.12-.902-.13-2.37-.13h-5c-1.468 0-1.98.01-2.37.13a3 3 0 0 0-2 2c-.12.39-.13.902-.13 2.37a1 1 0 1 1-2 0v-.174c0-1.227 0-2.065.215-2.777a5 5 0 0 1 3.334-3.334c.712-.216 1.55-.216 2.777-.215"
      clipRule="evenodd"
    />
  </svg>
);
SvgUser.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgUser;
