// judge which highlight part of discover module could display it for Discover module
export const generateDynamicStyles = (
  highlightFailureMode,
  highlightComponents,
  highlightFailureCondition,
  highlightAfterEffect,
  highlightSpecs,
) => {
  return `
        .failuremodeColor {
            background-color: ${highlightFailureMode ? "var(--danger-300)" : "transparent"};
        }
        .systemColor {
            background-color: ${highlightComponents ? "var(--brand-300)" : "transparent"};
        }
        .reasonColor {
            background-color: ${highlightFailureCondition ? "var(--warning-200)" : "transparent"};
        }
        .typeColor {
            background-color: ${highlightAfterEffect ? "#83c7ff" : "transparent"};
        }
        .specsColor {
            background-color: ${highlightSpecs ? "var(--success-400)" : "transparent"};
        }
    `;
};
