import React from "react";
import PropTypes from "prop-types";
const SvgSearch = ({ size = 24, color = "var(--icon-color)", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M11 4a7 7 0 1 0 4.856 12.041 1 1 0 0 1 .185-.185A7 7 0 0 0 11 4m7.032 12.618a9 9 0 1 0-1.414 1.414l3.675 3.675a1 1 0 0 0 1.414-1.414z"
      clipRule="evenodd"
    />
  </svg>
);
SvgSearch.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SvgSearch;
