// ToggleSwitch.js
import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ isOpen, onToggle, disabled = false, width = "50px", height = "25px" }) => {
  return (
    <div
      className={`toggle-switch ${isOpen ? "open" : ""} ${disabled ? "disabled" : ""}`}
      onClick={!disabled ? onToggle : null}
      role="switch"
      aria-checked={isOpen}
      tabIndex={0}
      style={{ width, height }} // Apply width and height
    >
      <div className="toggle-handle" style={{ width: `calc(${height} - 4px)`, height: `calc(${height} - 4px)`, transform: isOpen ? `translateX(calc(${width} / 2))` : "translateX(0)" }}></div>
    </div>
  );
};

// Define PropTypes for the component
ToggleSwitch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  width: PropTypes.string, // Width of the toggle switch
  height: PropTypes.string, // Height of the toggle switch
};

export default ToggleSwitch;

