// the temporary pop window: save hide and share
import React from "react";
import Save from "../../../Components/icons/General/Save";
import Share from "../../../Components/icons/General/Share";
import Hide from "../../../Components/icons/General/Hide";

const PopUpWindow = ({ selectedCount, onSave, onShare, onHide }) => {
  const handleShareClick = () => {
    onShare([]); // This will trigger email selection modal in parent
  };

  return (
    <div className="popUpWindow">
      <span>{selectedCount} selected</span>
      <button onClick={onSave}>
        <Save size={16} /> Save
      </button>
      <button onClick={handleShareClick}>
        <Share size={16} /> Share
      </button>
      <button onClick={onHide}>
        <Hide size={16} /> Hide
      </button>
    </div>
  );
};

export default PopUpWindow;
