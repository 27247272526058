import React from "react";
import PropTypes from "prop-types";

const TextField = ({ label, type, placeholder, isEditable }) => {
  return (
    <div className="text-field">
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        disabled={!isEditable} // Disable the input field if isEditable is false
      />
    </div>
  );
};

// Add PropTypes including isEditable as a boolean
TextField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isEditable: PropTypes.bool, // Add boolean for controlling editability
};

// Default value for isEditable (in case it’s not provided)
TextField.defaultProps = {
  isEditable: true, // By default, the field is editable
};

export default TextField;
