import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL + "/api";
const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
});

// Add interceptor to handle redirects
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.data?.redirect_url) {
      window.location.href = error.response.data.redirect_url;
      return;
    }
    return Promise.reject(error);
  }
);

export default api;
